import { FC, useEffect } from 'react';
import { InvitationTokenCard } from './invitation-token-card';
import { useHistory } from 'react-router-dom';
import useInvitationTokens from '../../../common/hooks/use-invitation-token';
import { useAppSelector } from '../../../store/hooks';
import { selectSlice } from '../../../store/slices/studies';

export interface InvitationTokenExplorerProps  {
  baseFilters?:any;
}

const InvitationTokenExplorer: FC<InvitationTokenExplorerProps>  = () => {
  const studies = useAppSelector(selectSlice)
  const [loadInvitations, loading, error, invitationTokens] = useInvitationTokens(studies?.selected?._id);
  const history = useHistory();

  function chooseInvitationToken (path: string, id: string) {
    history.push(path + '/' + id);
  }

  useEffect(() => {
    loadInvitations();
  }, [loadInvitations]);

  return (
    <div className="ticket-explorer">
      <div className="ticket-list">
        {invitationTokens?.map((invitationToken) => (
            <InvitationTokenCard key={invitationToken._id} {...invitationToken} onClick={chooseInvitationToken}/>
          ))
        }
        {loading && <p>Cargando datos</p>}
        {invitationTokens && invitationTokens?.length < 1 && <p style={{fontSize: '18px'}}>No existen links creados</p>} 
        </div>
    </div>
  );
}

export {
  InvitationTokenExplorer,
}