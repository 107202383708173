type ValidationType = 'email' | 'password' | 'empty';

function  validateEmail (email: string): void {
  if (!email) throw new Error('Ingresa tu email.');
  if (email.length < 3) throw new Error('Email incorrecto.');
  if (!email.includes('@')) throw new Error('Email incorrecto.');
}

function  validatePassword (password: string): void {
  if (!password) throw new Error('Ingresa tu contraseña.');
}

function  validateEmpty (value: string): void {
  if (!value) throw new Error('Los campos no pueden estar vacios.');
}

const fn: any = {
  email: validateEmail,
  password: validatePassword,
  empty: validateEmpty
};

function validate (validationType: ValidationType, value: any): void {
  fn[validationType](value);
}

export {
  validate
}