import React, { FC, InputHTMLAttributes } from 'react';

interface SelectOption {
  _id: string;
  name: string;
}

interface SelectInputProps extends InputHTMLAttributes<HTMLSelectElement> {
  label?: string;
  help?: string;
  noneLabel: string;
  selectedValue: string | undefined | null;
  options: SelectOption[] | undefined | null;
  onChange?: any;
}

const SelectInput: FC<SelectInputProps> = ({
  label,
  help,
  noneLabel,
  selectedValue,
  options,
  onChange,
  ...selectProps
}) => {

  function callOnChange (e: React.ChangeEvent<HTMLSelectElement>) {
    e.preventDefault();
    const newValue = e.target.value;
    onChange && onChange(newValue);
  }

  return (
    <>
      {label && <label className="input-label">{label}</label>}
      <select
        className="w-select"
        value={selectedValue || ''}
        onChange={callOnChange}
        {...selectProps}
        style={{
          backgroundColor: '#ffffff',
          width: '100%',
          borderRadius: '12px',
          backgroundPositionX: '90%',
          fontSize: '0.9em',
          border: '1px solid #dfe2e3',
        }}
      >
        <option value="">{noneLabel}</option>
        {options && options.map(({ _id, name }) => (
          <option key={_id} value={_id}>{name}</option>
        ))}
      </select>
      {help && <span style={{fontStyle: 'italic', fontSize: '13px'}}>{help}</span>}
    </>
  );

}

export default SelectInput;
export { SelectInput };