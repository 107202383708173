import { FC, useEffect } from 'react';
import useTickets from '../../../common/hooks/use-tickets';
import { TicketCard } from './ticket-card';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from '../../../store/hooks';
import { selectDateRange } from '../../../store/slices/filters';

export interface TicketExplorerProps  {
  baseFilters?:any;
}

const TicketExplorer: FC<TicketExplorerProps>  = ({ 
  baseFilters
}) => {
  const [loadTickets, loading, error, tickets] = useTickets({baseFilters});
  const history = useHistory();
  const dateRange = useAppSelector(selectDateRange);

  function chooseTicket (id: string, path: string = '/detail-tickets') {
    history.push(path + '/' + id);
  }

  useEffect(() => {
    loadTickets();
  }, [baseFilters, dateRange]);

  return (
    <div className="ticket-explorer">
      <div className="ticket-list">
        {tickets?.map((ticket) => (
            <TicketCard key={ticket._id} {...ticket} onClick={chooseTicket}/>
          ))
        }
        {loading && <p>Cargando datos</p>}
        {tickets && tickets?.length < 1 && <p style={{fontSize: '18px'}}>No existen tickets</p>} 
        </div>
    </div>
  );
}

export {
  TicketExplorer,
}