import React, { FC } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectDateRange, setDateRange, setCompareRange, selectCompareRange, setFiltersActive, setCompare } from '../../store/slices/filters';
import DateRangeInput from './date-range-input';
import { FilterButton } from './filters';
import { MainFilters } from './filters/main-filters';

interface PageHeaderProps {
  title: string;
  action?: any;
  actionLabel?: string;
  showFilters: boolean;
  showRangeInput?: boolean;
  showRangeInputCompare?: boolean;
  disableRefresh?: boolean;
  showMainFilters?: boolean;
}

const PageHeader: FC<PageHeaderProps> = ({
  title,
  action,
  actionLabel,
  showFilters,
  showRangeInput,
  showRangeInputCompare,
  disableRefresh,
  showMainFilters,
}) => {
  const dispatch = useAppDispatch();
  const dateRange = useAppSelector(selectDateRange);
  const compareRange = useAppSelector(selectCompareRange);

  return (
    <>
    <div className="page-header">
      <div className="page-title">
        <h1 style={{ lineHeight: '40px' }}>{title}</h1>
        {action && <button className="page-action-button" onClick={action}>{actionLabel}</button>}
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
        {showFilters && <FilterButton />}
        {showRangeInput && (
          <DateRangeInput
            initialValue={dateRange}
            onRangeChange={(payload: any) => {
              dispatch(setFiltersActive())
              dispatch(setDateRange(payload));
            }}
            disabled={disableRefresh}
            showRangeInputCompare={showRangeInputCompare}
            compareInitialValue={compareRange}
            onCompareRangeChange={(payload: any) => {
              dispatch(setCompareRange(payload));
            }}
            onCompareCheck = {(payload: any) => {
              dispatch(setCompare(payload));
            }}
          />
        )}
      </div>
    </div>
    {showMainFilters && <MainFilters />}
    </>
  );
}

export default PageHeader;
export {
  PageHeader,
}