import { Survey } from "../../utils";

export enum StatusLink {
  ACTIVE = 'active',
  DISABLED = 'disabled',
}

export enum TypeLink {
  PUBLIC = '1',
  INDIVIDUAL = '2'
}

export enum TimeUnitToken {
  HOUR = 'hour',
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
}

export type LimitInvitationToken = {
  maxAnswers?: string;
  timeUnit?: TimeUnitToken;
}

export type InvitationToken = {
  _id?: string;
  token?: string;
  defaultData?: any;
  surveyId: string;
  surveyObj?: Survey;
  status?: StatusLink;
  type: TypeLink;
  rules?: string[];
  limit?: LimitInvitationToken;
  archived?: boolean;
}

export type InvitationTokens = InvitationToken[] | undefined;
