import { FC } from 'react';

interface ModalProps {
  open: boolean;
  showLoadingAnimation: boolean;
  text: string;
  onClick?: () => void;
};

const Modal: FC<ModalProps> = ({
  open,
  showLoadingAnimation,
  text,
  onClick,
  children = undefined
}) => {
  return (
    <div style={{ display: open ? 'flex' : 'none' }} className='modal'>
      <div className='modal-content'>
        { showLoadingAnimation && <div className='loader'></div> }
        { showLoadingAnimation && <div className='modal-text'>{text}</div> }
        { !showLoadingAnimation && 
          <div>
            <div className='modal-text-small'>{text}</div>
            {children}
            <div className='modal-content-button'>
              <button type='button' className='modal-button' onClick={onClick}>Aceptar</button>
            </div>
          </div>
        }
      </div>
    </div>
  );
}

export {
  Modal
};
