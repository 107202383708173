import { useState, useCallback } from 'react';
import { useAppSelector } from '../../store/hooks';
import { selectSlice } from '../../store/slices/studies';
import { Filter, selectDateRange, selectFilters } from '../../store/slices/filters';
import { reports } from '../../services';
import { SummaryImportedRecordQuery, SummaryImportedRecords } from '../../services/reports';
import { messages } from '../../utils';

export type SummaryImportedRecordsConfig =
  Omit<SummaryImportedRecordQuery, 'studyId' | 'filters' | 'dateRange'> & {
    dateRangeField: string,
    baseFilters?: Filter[],
  }
type UseCSATAttributesReportHook = (config: SummaryImportedRecordsConfig) => [
  () => Promise<any>,
  boolean,
  Error | undefined,
  SummaryImportedRecords | undefined
];

const useSummaryImportedRecords: UseCSATAttributesReportHook = ({
  dateRangeField,
  baseFilters = [],
}) => {
  const studies = useAppSelector(selectSlice);
  const filters = useAppSelector(selectFilters);
  const [loading, setLoading] = useState(true);
  const [analyticsError, setAnalyticsError] = useState<Error | undefined>();
  const [analyticsResult, setAnalyticsResult] = useState<SummaryImportedRecords | undefined>(undefined);
  const dateRange = useAppSelector(selectDateRange);
  const hasBaseFilters = baseFilters.length;
  const execRequest = useCallback(
    async () => {
      try {
        setAnalyticsResult(undefined);
        setAnalyticsError(undefined);
        setLoading(true);
        if (!studies.selected) {
          return setAnalyticsError(new Error('No hay estudio seleccionado'));
        }
        if (!dateRange.start || !dateRange.end) {
          return setAnalyticsError(new Error('No hay periodo de tiempo seleccionado'));
        }
        let query:any = {
          [dateRangeField]: {
            $gte: dateRange.start.startOf('day').toISOString(),
            $lte: dateRange.end.endOf('day').toISOString(),
          }
        }
        let newFilters: any[] = [];
        if (hasBaseFilters) {
          newFilters = baseFilters
        }
        if (filters.length) {
          const standarizedFilters = filters.map(f => ({
            field: f.field,
            values: f.values.map(v => v.value),
          }));
          if (hasBaseFilters) {
            const filtersToOverride = standarizedFilters.map(bf => bf.field)
            const newBaseFilters = baseFilters.filter(f => !filtersToOverride.includes(f.field))
            newFilters = [...newBaseFilters, ...standarizedFilters]
          } else {
            newFilters = standarizedFilters
          }
        }
        if (newFilters.length > 0 ) {
          newFilters.forEach((f) => {
            if (f.values) Object.assign(query, {[f.field]: { $in: f.values }});
            else Object.assign(query, {[f.field]: { $exists: true }});
          });
        }
        let analyticsResponse = await reports.getSummaryImportedRecords(studies.selected._id, query);
        setAnalyticsResult(analyticsResponse);
      } catch (err) {
        setAnalyticsError(err as Error);
      } finally {
        setLoading(false);
      }
    },
    [
      studies.selected,
      dateRange,
      filters,
    ]
  );
  
  return [
    execRequest,
    loading,
    analyticsError,
    analyticsResult,
  ]
}

export default useSummaryImportedRecords;