import { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { parseFilters } from '../../services/filters';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { clearFilters, FilterOptions, setAvailableFilters, setCompare, setMainFilters } from '../../store/slices/filters';
import { selectSlice } from '../../store/slices/studies';
import { ViewConfig, getViewConfig } from '../../services/views';

type UseViewHook = () => {
  loadingView: boolean;
  loadViewConfig: () => Promise<void>;
  viewConfig?: ViewConfig
}

export const useViewConfig: UseViewHook = () => {
  const studies = useAppSelector(selectSlice);
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const [loadingView, setLoadingView] = useState(false);
  const [viewConfig, setViewConfig] = useState<ViewConfig | undefined>();
  const loadViewConfig = useCallback(
    async () => {
      try {
        setLoadingView(true);
        dispatch(clearFilters());
        dispatch(setCompare(false));
        const viewConfig = await getViewConfig({
          studyId: studies?.selected?._id,
          viewName: pathname.replace('/', '')
        });
        let availableFilters: FilterOptions[] = [];
        if (viewConfig) {
          availableFilters =
            parseFilters(viewConfig.mainFiltersObj).concat(parseFilters(viewConfig.filtersObj));
        }
        dispatch(setAvailableFilters(availableFilters));
        dispatch(setMainFilters(viewConfig.mainFiltersObj.map(mf => mf.prefix)));
        setViewConfig(viewConfig);
      } catch (e) {
        console.warn(e);
      } finally {
        setLoadingView(false);
      }
    },
    [studies.selected]
  );

  useEffect(() => {
    return () => {
      dispatch(clearFilters())
    }
  }, [dispatch]);

  return {
    loadingView,
    loadViewConfig,
    viewConfig,
  }

}