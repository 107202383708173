import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { setStudy, selectStudies, setAvailable } from '../../store/slices/studies';
import { selectAvailableViews, setAvailableViews } from '../../store/slices/views';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { CenteredLayout } from '../../common/layouts';
import StudiesEmpty from './widgets/studies-empty.card';
import StudiesCard from './widgets/studies.card';
import { translateError } from '../../utils/handle-error';
import AppsCard from './widgets/apps.card';
import { studies } from '../../services';
import { listViews } from '../../services/views';

const StartScreen = () => {
  const dispatch = useAppDispatch();
  const myStudies = useAppSelector(selectStudies);
  const availableViews = useAppSelector(selectAvailableViews);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  function chooseStudy (_id: string, path: string = '/overview') {
    dispatch(setStudy(_id));
    localStorage.setItem('studyId', _id)
    history.push(path);
  }

  async function getStudies () {
    const studyList = await studies.getStudies();
    if (!studyList) return;
    const availableViews = await listViews();
    dispatch(setAvailable(studyList));
    dispatch(setAvailableViews(availableViews));
  }

  useEffect(() => {
    getStudies();
    async function loadMyStudies() {
      try {
        if (!myStudies) return;
        if (myStudies.length === 1 && !process.env.REACT_APP_SHOW_TICKETS) {
          const initialScreen = availableViews.find(s => s.viewOptions?.showOnNavbar);
          setTimeout(() => 
            chooseStudy(myStudies[0]._id, initialScreen?.viewName),
            300
          )
          
        } else {
          setLoading(false);
        }
      } catch (err) {
        const error = translateError(err);
        alert(error);
      }
    }
    loadMyStudies();
  }, []);

  if (loading) return null;  
  
  return (
    <CenteredLayout
      title="Selecciona un estudio"
      useSimpleNav
    >
      <div className="studies-grid">
        { myStudies.length === 0 && (
          <StudiesEmpty />
        ) }
        { myStudies.length > 0 && myStudies.map(st => (
          <StudiesCard key={st._id} {...st} onClick={chooseStudy} />
        ))} 
        {process.env.REACT_APP_SHOW_TICKETS && <AppsCard name="Tickets" path='/pending-tickets'/>}
      </div>
    </CenteredLayout>
  );
};

export default StartScreen;
