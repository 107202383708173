import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { selectSlice, setStudy } from '../../store/slices/studies';
import { selectAvailableViews } from '../../store/slices/views';

const StudySelector: FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const availableViews = useAppSelector(selectAvailableViews)
  const studies = useAppSelector(selectSlice);
  const tickets = window.location.pathname.includes('tickets')
  const selectedStudy = studies.selected?._id;
  const [selectedId, setSelectedId] = useState<string | undefined>(selectedStudy)

  function callOnChange (e: React.ChangeEvent<HTMLSelectElement>) {
    e.preventDefault();
    const newId = e.target.value;
    if(newId === 'pending-tickets') history.push('/pending-tickets');
    else {
      dispatch(setStudy(newId));
      setSelectedId(newId);
      const enabledLinks = availableViews.filter(view => view.viewOptions.showOnNavbar && newId === view.studyId)
      history.push(enabledLinks[0].viewName);
    }
    localStorage.setItem('studyId', newId)
  }

  return (
    <select
      className="navbar-select w-select"
      value={ tickets ? 'pending-tickets' : selectedId }
      onChange={callOnChange}
    >
      {studies.available.map(({ _id, name }) => (
        <option key={_id} value={_id}>{name}</option>
      ))}
      {process.env.REACT_APP_SHOW_TICKETS && <option key='pending-tickets' value='pending-tickets'>Tickets</option> }
    </select>
  );

}

export default StudySelector;
export { StudySelector };