import React, { useEffect, useState } from 'react'
import useSegmentedReport, { SegmentedQuery } from '../../../common/hooks/use-segmented-report'
import BaseTable from '../../../common/widgets/table.w'
import { GridCellParams, GridColDef, GridValueGetterParams } from '@mui/x-data-grid'
import { LocalizedText, SupportedLanguages } from '../../../services/filters'
import { sortBy } from '../../../utils'
import { ReportConfig } from '../../../services/views'
import { Tooltip } from '@material-ui/core';

export interface ScaleFormatDef {
  max: number,
  className: string,
}

export type SegmentedReportTableOptions = {
  groupResultDisplay?: LocalizedText,
  resultToDisplay: 'n' | 'result',
  scaleFormatMode?: 'first' | 'all',
  scaleFormatters: Record<string, ScaleFormatDef[]>,
  varsTitle?: LocalizedText,
  breakdownUseDistinctId?: boolean,
}

export type SegmentedReportTableProps = ReportConfig<
  SegmentedQuery,
  SegmentedReportTableOptions
>

const makeGetDisplay = (field: string, language: SupportedLanguages): GridColDef['valueGetter'] =>
  (params: GridValueGetterParams) => params.row[field]?.[language] ?? '-'

const baseColProps: Partial<GridColDef> = {
  minWidth: 144,
  headerAlign: 'right',
  align: 'right',
  type: 'number',
  sortable: true,
}

const makeGetCellClassName = (scaleFormatter: ScaleFormatDef[]) => {
  if (!scaleFormatter) return () => ''
  scaleFormatter.sort(sortBy('max'))
  return (params: GridCellParams) => {
    if (typeof params.value !== 'number') return '';
    const scale = scaleFormatter.find(scale => scale.max > (params.value as number))
    if (scale) {
      return scale.className;
    }
    return '';
  }
}

const SegmentedReportTable = ({
  title = { default: '', es: '' },
  query,
  options: {
    groupResultDisplay = { es: '', default: '' },
    resultToDisplay,
    scaleFormatMode,
    scaleFormatters,
    varsTitle = { es: ' ', default: ' ' },
    breakdownUseDistinctId,
  },
}: SegmentedReportTableProps) => {
  const [loadReport, loading, error, segmentedReport] = useSegmentedReport(query);
  const [tableCols, setTableCols] = useState<GridColDef[]>([]);
  const [data, setData] = useState<Array<any>>([]);
  const preferredLang = SupportedLanguages.es
  const cols: GridColDef[] = [
    {
      field: 'display',
      type: 'string',
      headerName: varsTitle[preferredLang],
      disableColumnMenu: true,
      width: 320,
      valueGetter: makeGetDisplay('display', preferredLang),
      renderCell: ({ value }: GridCellParams) => (
        <Tooltip title={value || ''} >
          <span>{value}</span>
        </Tooltip>
      ),
      sortable: false,
    },
  ]

  useEffect(() => {
    loadReport();
  }, [loadReport]);

  useEffect(() => {
    if (!loading && !error && segmentedReport) {
      const firstGroup = segmentedReport.groups.shift()
      const extendedCols: GridColDef[] =
        cols.concat(segmentedReport.breakDownDetail.map(
          segment => ({
            ...baseColProps,
            field: segment.distinctId,
            headerName: breakdownUseDistinctId ? segment.distinctId : segment.displayValue.es,
            cellClassName: (params: GridCellParams) => {
              if (
                (scaleFormatMode === 'first' && params.id === `${query.groups?.[0].distincId}_${query?.groups?.[0]?.groupCalc?.type}`) ||
                scaleFormatMode === 'all'
              ) {
                return makeGetCellClassName(scaleFormatters[params.id])(params)
              }
              return ''
            }
          }))
        )
      const newData = firstGroup ? firstGroup.detail.map((fullResult, i) => {
        let segments: Record<string, any> = {}
        let { breakdown, display, ...segmentedResult } = fullResult
        for (const k in breakdown) {
          segments[k] = breakdown[k][resultToDisplay]
        }
        
        return {
          display: i === 0 && query.groups[0]?.groupCalc ? groupResultDisplay : display,
          ...segmentedResult,
          ...segments,
        }
      }) : [];
      setTableCols(extendedCols);
      setData(newData);
    }
  }, [loading, error, segmentedReport]);

  return (
    <div className="span-two-cols">
      <BaseTable
        rowIdKey="distinctId"
        rows={data}
        columns={tableCols}
        loading={loading}
        onReload={loadReport}
        rowHeight={32}
        hideFooter
        autoHeight
        tableTitle={title[preferredLang]}
        downloadFileName={title[preferredLang]}
        localeText={{
          toolbarFilters: '',
          toolbarExport: '',
          toolbarColumns: 'Columnas',
          noRowsLabel: 'No se encontraron registros.',
          toolbarExportCSV: 'Descargar CSV',
          toolbarExportLabel: 'Exportar',
          toolbarFiltersTooltipShow: 'Mostrar filtros',
          toolbarFiltersTooltipHide: 'Ocultar filtros',
        }}
      />
    </div>
  )
}

export {
  SegmentedReportTable
}