import React, { FC, useEffect } from 'react';
import { MainLayout } from '../../common/layouts';
import { PageHeader } from '../../common/components';
import { CommentExplorer } from './widgets/comment-explorer.w';
import { useViewConfig } from '../../common/hooks/use-view-config';
import { ViewBanner } from '../../common/components/view-banner';

const CommentsScreen: FC = () => {
  const { loadingView, loadViewConfig, viewConfig } = useViewConfig();

  useEffect(() => {
    loadViewConfig();
  }, [loadViewConfig]);

  return (
    <MainLayout>
      {loadingView ? (
        <div>Cargando vista...</div>
        ) : viewConfig && viewConfig.reports ? (
          <>
            {viewConfig.infoMessage ? (
              <ViewBanner {...viewConfig.infoMessage} />
            ) : null}
            <PageHeader
              title="Comentarios"
              showFilters
              showRangeInput
              showMainFilters
            />
            <CommentExplorer {...viewConfig.reports[0]} />
          </>
        ) : (
          <div>Ocurrió un problema al cargar los comentarios</div>
        )}
    </MainLayout>
  );

}

export {
  CommentsScreen,
}