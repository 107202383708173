import { FC, useEffect } from 'react';
import { MainLayout } from '../../common/layouts';
import { PageHeader } from '../../common/components';
import { CampaignExplorer } from './widgets/campaigns-explorer';
import { useViewConfig } from '../../common/hooks/use-view-config';
import { ViewBanner } from '../../common/components/view-banner';

interface CampaignsScreenProps {
};

const CampaignsScreen: FC<CampaignsScreenProps> = () => {
  const { loadingView, loadViewConfig, viewConfig } = useViewConfig();
  useEffect(() => {
    loadViewConfig();
  }, [loadViewConfig]);
  
  return (
    <MainLayout>
      {loadingView ? (
        <div>Cargando vista...</div>
        ) : viewConfig ? (
          <>
            {viewConfig.infoMessage ? (
              <ViewBanner {...viewConfig.infoMessage} />
            ) : null}
            <PageHeader
              title={viewConfig.viewOptions.pageTitle}
              showFilters={false}
              showMainFilters={false}
              showRangeInput={false}
            />
             <CampaignExplorer /> 
          </>
        ) : (
          <div>Ocurrió un problema al cargar las campañas.</div>
        )}
    </MainLayout>
  );
}

export {
  CampaignsScreen,
}