import React, { FC, InputHTMLAttributes } from 'react';

interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  help?: string;
  fieldName: string;
  inputProps?: any;
};

const defaultInputProps: InputHTMLAttributes<HTMLInputElement> = {
  type: 'text',
  maxLength: 256,
};

const TextInput: FC<TextInputProps> = ({
  label,
  help,
  fieldName,
  ...inputProps
}) => {
  return (
    inputProps.type === "checkbox" 
    ? <div className="form-control-check">
        <input name={fieldName} className="input-check" {...defaultInputProps} {...inputProps} />
        <label htmlFor={fieldName} className="input-label">{label}</label>
        {help && <span style={{fontStyle: 'italic', fontSize: '13px', lineHeight: '16px', marginLeft: '12px'}}>{help}</span>}
    </div>
    : <div className="form-control">
      <label htmlFor={fieldName} className="input-label">{label}</label>
      <input name={fieldName} className="text-input w-input" {...defaultInputProps} {...inputProps} />
      {help && <span style={{fontStyle: 'italic', fontSize: '13px'}}>{help}</span>}
    </div>
  );
}

export default TextInput;
export {
  TextInput,
};