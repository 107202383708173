import { FC } from 'react';
import moment from 'moment';
import './ticket.css';
import {  Message, StatusMessages } from '../../../services/tickets/tickets.models';

export interface MessageProps extends Message {
}

const MessageCard: FC<MessageProps> = ({
  createdAt,
  subject,
  body,
  status
}) => (
  <div className="message-card">
    <div className="message-status"><b>{status === StatusMessages.CREATED ? "Enviando" : status === StatusMessages.SEND ? "Enviado" : status === StatusMessages.RECEIVED ? "Recibido" : "Error"}</b> <b>{moment(createdAt).format('DD/MM/YY HH:mm')}</b></div>
    <p className="message-subject"><b>Asunto: </b> {subject}</p>
    <p className="message-body"><b>Mensaje:</b></p>
    <div dangerouslySetInnerHTML={{ __html: body }} />
    <br />
  </div>
);

export {
  MessageCard,
}