import { FC, useState } from 'react';
import './invitation-token.css';
import { InvitationToken, StatusLink, updateInvitationToken } from '../../../services/invitation-token';
import { SURVEY_URL } from '../../../utils/constants';

export interface InvitationTokenProps extends InvitationToken {
  onClick: any;
}

const InvitationTokenCard: FC<InvitationTokenProps> = ({
  _id,
  token,
  status,
  surveyObj,
  onClick
}) => {
  const [loadUpdate, setLoading] = useState(true);

  async function onUpdate(): Promise<void> {
    try {
      setLoading(true);
      if (status && _id) {
        const newStatus =  status === StatusLink.ACTIVE ? StatusLink.DISABLED : StatusLink.ACTIVE;
        const response = await updateInvitationToken(_id, { status: newStatus })
        if (response) {
          window.location.reload();
        }
      }
    } catch (error) {
      console.log('Error: ', error)
      alert("El enlace no se pudo actualizar correctamente")
    }
    finally{
      setLoading(false);
    }
  }

  return (
    <div className="invitation-card">
      <div className="invitation-status"><b>{status === StatusLink.ACTIVE ? "Activo" : "Deshabilitado"}</b></div>
      <p className="invitation-title">{token}</p>
      <p className="invitation-text">Encuesta: {surveyObj?.name}</p>
      <p className="invitation-text">Enlace: <a href={`${SURVEY_URL}/${token}`} target="_blank" rel="noreferrer">{SURVEY_URL}/{token}</a></p>
      <br />
      <p className="invitation-text">QR:</p>
      <a href={`https://quickchart.io/qr?text=${SURVEY_URL}/${token}`} target="_blank" rel="noreferrer"><img alt="QR" src={`https://quickchart.io/qr?text=${SURVEY_URL}/${token}`} /></a>
      <div className='invitation-actions-container'>
        <button className="invitation-button" disabled={!loadUpdate ? true : false} onClick={onUpdate}>{status === 'active' ? "Desactivar enlace" : "Activar enlace"}</button>
        <button className="invitation-button" disabled={!loadUpdate ? true : false} onClick={() => onClick('/update-link', _id)}>Editar datos</button>
      </div>
    </div>
  );
  }

export {
  InvitationTokenCard,
}