import { storage } from '..';
import { ServerResponse, constants } from '../../utils';
import { Message, Ticket, TicketsList } from './tickets.models';

export interface getOptions {
  ticketId: string;
}

export interface AnswerTicket {
  subject: string;
  body: string;
}

async function listTickets(): Promise<Ticket[]> {
  const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    }
  };
  let URL = `${constants.API_URL}/ticket`;
  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: ServerResponse<Ticket[]> = await fetchResponse.json();
  const ticketList = response.data;
  if (!ticketList) throw new Error('No tickets found')

  return ticketList;
}

async function getTicketsList (options: any): Promise<TicketsList> {
  const {
    range,
    dateRangeField = 'createdAt',
    filters,
  } = options;

  const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);
  if (!range?.start || !range?.end) throw new Error ('Falta rango de fecha.');
  let body: any = {
    dateRange: {
      field: dateRangeField,
      start: range.start.startOf('day').toISOString(),
      end: range.end.endOf('day').toISOString(),
    }
  };
  if (filters && filters.length > 0) {
    body.filters = filters
  }
  const fetchOpts: RequestInit = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body),
  };
  let URL = `${constants.API_URL}/ticket/list-tickets`;
  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response = await fetchResponse.json();
  return response.tickets;
}

async function getTicket ({
  ticketId
}: getOptions): Promise<Ticket> {
  const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    }
  };
  let URL = `${constants.API_URL}/ticket/${ticketId}`;

  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: ServerResponse<Ticket> = await fetchResponse.json();
  const ticket = response.data;
  if (!ticket) throw new Error('No ticket found')

  return ticket;
}

async function answerTicket (id:string, data: AnswerTicket): Promise<Message> {
  const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data)
  };

  let URL = `${constants.API_URL}/ticket/answer/${id}`;

  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: ServerResponse<Message> = await fetchResponse.json();
  const message = response.data;
  if (!message) throw new Error('No report found')

  return message;
}

async function updateTicket (id:string, data: any): Promise<Ticket> {
  const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data)
  };

  let URL = `${constants.API_URL}/ticket/${id}`;

  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: ServerResponse<Ticket> = await fetchResponse.json();
  const message = response.data;
  if (!message) throw new Error('No report found')

  return message;
}

export {
  getTicket,
  listTickets,
  getTicketsList,
  answerTicket,
  updateTicket
}