import { CenteredLayout } from '../../common/layouts';
import { FileUploadForm } from './widgets';

const NewUploadScreen = () => {
  return (
    <CenteredLayout
      title="Cargar archivo de contactos"
      navbarProps={{ showControls: true, showMenu: true }}
    >
      <FileUploadForm/>
    </CenteredLayout>
  );
}

export {
  NewUploadScreen,
};