import React, { useEffect } from 'react'
import { CalcParams, CalcType } from '../../../services/reports'
import useKpiSummary from '../../../common/hooks/use-kpi-summary'
import './kpi-summary.w.css'
import { getColorByKey, toDelta, toNumber, toPercentage } from '../../../utils'
import { CardMessage } from '../../../common/components/widget-card-message.w'
import { ReportConfig } from '../../../services/views'
import { useAppSelector } from '../../../store/hooks'
import { selectCompareRange } from '../../../store/slices/filters'

export type KpiSummaryConfig = ReportConfig<
  {
    dateRangeField: string,
    field: string,
    calc: CalcParams
  },
  {
    kpiName: string,
    compareWithPreviosPeriod: boolean,
    showBar: boolean
  }
>

const KpiSummary = ({
  title = { default: '', es: '' },
  query,
  options: {
    kpiName,
    compareWithPreviosPeriod,
    showBar
  },
}: KpiSummaryConfig) => {
  const compareDateRange = useAppSelector(selectCompareRange);
  const [loadReport, loading, error, kpiSummary] = useKpiSummary((Object.assign(query, {compareWithPreviosPeriod: compareWithPreviosPeriod !== undefined ? compareWithPreviosPeriod : true })));

  useEffect(() => {
    loadReport();
  }, [loadReport]);

  if (loading) return <CardMessage />
  if (error || !kpiSummary) return (
    <CardMessage message={{ default: 'Error on report', es: `Error al cargar reporte: ${error?.message}` }} />
  )

  let reportData = kpiSummary.report.detail;

  if (reportData.length === 0) return (
    <CardMessage message={{ default: 'Error on report', es: `No se encontró información en el rango de la consulta` }} />
  )
  if (kpiName === 'Respuestas') reportData = kpiSummary.report.detail.slice().sort(
    (p1, p2) => (p1.distinctId < p2.distinctId) ? 1 : (p1.distinctId > p2.distinctId) ? -1 : 0);
  const resultDiff = compareWithPreviosPeriod && kpiSummary?.comparision ? kpiSummary.report?.result - kpiSummary?.comparision?.result : 0;
  const showBars = showBar !== undefined ? showBar : true;
  const showQuestionName = query.calc.type === CalcType.QUESTION 
  const percentage = query.calc.type === CalcType.CSAT ? '%' : '';
  if (showQuestionName) reportData = kpiSummary.report.detail.slice().sort((p1, p2) => (p1.n < p2.n) ? 1 : (p1.n > p2.n) ? -1 : 0);

  return (
    <div className="kpi-widget">
      <div className="chart-widget-card-header">
        <div>
          <b>{title.es}</b>
        </div>
      </div>
      {
        showQuestionName 
        ? <div className="card-question">{kpiSummary.report.question?.es}</div>
        : <>
            <div className="card-value">{kpiSummary.report.result + percentage}</div>
            <div className="card-label">{kpiName}</div>
          </>
      }
      <div className="detail-row">
      {showBars && reportData.map((segment, idx) => (
        <>
          <div style={{ textAlign: 'right' }}>{toNumber(segment.n)}</div>
          <div style={{ color: '#889da7' }}>{segment.display.display.es}</div>
          <div className="progress-meter">
            <div className="progress-bar" style={{ width: `${segment.percentage}%`, backgroundColor: getColorByKey(showQuestionName ? segment.display.display.es : segment.distinctId, idx) }}></div>
          </div>
          <div>
            <span>{toPercentage(segment.percentage, 2)}</span>
          </div>
        </>
      ))}
      </div>
      <div className="card-support muted-text">n = {toNumber(kpiSummary.report.n)}</div>
      {(compareWithPreviosPeriod && compareDateRange.start && compareDateRange.end && kpiSummary?.comparision) &&
        <div className="comparative">
          {resultDiff > 0 ? <div className="delta-inc"></div> : <div className="delta-dec"></div>}
          <div className="card-comparative-data">{toDelta(resultDiff.toFixed(2))}</div>
          <div className="muted-text">vs periodo comparado</div>
        </div>
      }
    </div>
  )
}

export {
  KpiSummary
}