import { FC, useEffect, useState } from 'react'
import { useAppSelector } from '../../../store/hooks'
import { selectSlice } from '../../../store/slices/studies'
import { SelectInput } from '../../../common/components/select-input'
import { getFieldsSurvey } from '../../../services/surveys.services'
import { DataType, Fields, InvitationRules } from '../../../utils'
import { TextInput } from '../../../common/components'
import { SURVEY_URL } from '../../../utils/constants'
import { InvitationToken, TypeLink, createInvitationToken, getInvitationToken, updateInvitationToken, verifyToken } from '../../../services/invitation-token'
import { useHistory } from 'react-router-dom'
import { Modal } from '../../../common/components/modal'

interface InvitationTokenFormProps {
  id?: string
};

const InvitationTokenForm: FC<InvitationTokenFormProps> =({
  id
}) => {
  const studies = useAppSelector(selectSlice)
  const [surveyId, setSurveyId] = useState<string | undefined>('')
  const [token, setToken] = useState<string | undefined>('')
  const [maxAnswers, setMaxAnswers] = useState<string | undefined>('')
  const [timeUnit, setTimeUnit] = useState<string | undefined>('')
  const [tokenId, setTokenId] = useState<string | undefined>('')
  const [validToken, setValidToken] = useState<boolean>(false)
  const [suggestedToken, setSuggestedToken] = useState<string | undefined>('')
  const [type, setType] = useState<string | undefined>('')
  const [linkParams, setLinkParams] = useState<Record<string, string>>({})
  const [fields, setFields] = useState<Fields>([])
  const [invitationRules, setInvitationRules] = useState<InvitationRules>([])
  const [rules, setRules] = useState<string[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [textModal, setTextModal] = useState<string>('')
  const [dataEdition, setDataEdition] = useState<InvitationToken | undefined>()
  const history = useHistory();

  async function setData(){
    if(dataEdition){
      setToken(dataEdition.token)
      setTokenId(dataEdition.token)
      setLinkParams(dataEdition.defaultData)
      setType(dataEdition.type)
      if (dataEdition.rules && dataEdition.rules.length > 0) {
        setRules(dataEdition.rules)
        dataEdition.rules.forEach(id => {
          const element:any = document.getElementById(id);
          if (element) element.checked = true;
        });
      }
      if (dataEdition?.limit?.maxAnswers) setMaxAnswers(dataEdition.limit.maxAnswers)
        if (dataEdition?.limit?.timeUnit) setTimeUnit(dataEdition.limit.timeUnit)
    }
  }

  useEffect(() => {
    function setSurvey(){
      if(studies) setSurveyId(studies?.selected?.surveys[0]);
    }
    setSurvey();
  }, [studies]); 

  useEffect(() => {
    async function getFields(){
      if (surveyId && type && type !== "") {
        const result = await getFieldsSurvey(surveyId, type);
        if(result?.fields && result.fields.length > 0) {
          setFields(result.fields);
        }
        if(result?.token) {
          setSuggestedToken(result.token);
          setValidToken(true);
        }
        if(result?.invitationRules) {
          setInvitationRules(result.invitationRules);
          if (id  && dataEdition?.type === type) setData()
        }
      }
    }
    getFields();
  }, [surveyId, type]);

  useEffect(() => {
    async function getData(){
      if(id){
        const data = await getInvitationToken({id})
        if (data) setDataEdition(data)
      }
    }
    getData()
  }, [id]);

  useEffect(() => {
    setData()
  }, [dataEdition]);

  function reset(){
    setFields([])
    setLinkParams({})
    setToken('')
    setSuggestedToken('')
    setValidToken(false);
    setMaxAnswers('');
    setTimeUnit('')
  }

  async function handleVerify(){
    if (tokenId === token) setValidToken(true)
    else if(token) {
      const result = await verifyToken(token);
      if (result.data?.hasOwnProperty('valid')) setValidToken(result.data.valid)
      else alert('Error al crear: ' + result.message.es ? result.message.es :result.message);
    } 
    else {
      if (suggestedToken) setValidToken(true)
    }
  }

  async function handleSubmit (event: any) {
    event.preventDefault();
    if (surveyId) {
      try {
        setLoading(true);
        setTextModal('Procesando...');
        setOpenModal(true);
        const fieldNames = fields ? fields.map(field => field.fieldName) : [];
        for (let key in linkParams) {
          if (linkParams[key] === "" || !fieldNames.includes(key)) delete linkParams[key];
        }
        const limit:any = {}
        if (maxAnswers !== "") limit.maxAnswers = maxAnswers
        if (timeUnit !== "") limit.timeUnit = timeUnit
        if (id) {
          const created = await updateInvitationToken(id, {token, type, defaultData: linkParams, rules, limit})
          if(created.data)  {
            setLoading(false);
            setTextModal('Invitacion actualizada correctamente');
          }
          else {
            setTextModal('Error al crear: ' + created.message.es ? created.message.es :created.message);
            setLoading(false);
          }
        } else {
          const newInvitationToken:any = {
            surveyId: surveyId,
            type,
            defaultData: linkParams,
            limit
          }
          if (token) newInvitationToken.token = token;
          else newInvitationToken.token = suggestedToken;
          if (rules.length > 0) newInvitationToken.rules = rules;
          const created = await createInvitationToken(newInvitationToken)
          if(created.data)  {
            setLoading(false);
            setTextModal('Invitacion creada correctamente');
          }
          else {
            setLoading(false);
            setTextModal('Error al crear: ' + created.message.es ? created.message.es :created.message);
          }
        }
      } catch (error) {
        setLoading(false);
        setTextModal('Error al crear: ' + error);
      }
    }
  }

  const handleCheckboxChange = (event: any) => {
    let newArray = [...rules, event.target.id];
    if (rules.includes(event.target.id)) {
      newArray = newArray.filter(id => id !== event.target.id);
    }
    setRules(newArray);
  };

  function clickModal() {
    if (textModal.includes('correctamente')) {
      history.push("/links");
    } else {
      setOpenModal(false);
    }
  }
  
  return (
    <>
      <Modal
        open={openModal}
        showLoadingAnimation={loading}
        text={textModal}
        onClick={ () => clickModal()}
      />
      <div style={{
        flex: 1,
        paddingTop: '5rem',
        display: 'flex',
        alignSelf: 'stretch',
        justifyContent: 'center'
      }}>
      <form style={{ 
        width: '80%', 
        border: '1px solid #DFE2E3',
        borderRadius: '16px',
        backgroundColor: 'white',
        padding: '24px'
      }} onSubmit={handleSubmit}>
        <h5>Configura tu enlace</h5>
        <SelectInput
          label='Elige el tipo de enlace, si es un enlace publico o para una persona en especifico *'
          noneLabel='Selecciona una opción'
          selectedValue={type}
          onChange={(data: string) => {setType(data); reset();}}
          required={true}
          options={[
            {_id: '1', name: 'Público'},
            {_id: '2', name: 'Individual'},
          ]}
          style={{
            marginBottom: '16px',
          }}
        />
        <p style={{ margin: '8px', fontSize: '14px', fontStyle:'italic', color:'#3b5998'}}>Al cambiar el tipo de enlace se perderan los cambios que hayas realizado a tu configuración</p>
        {type === TypeLink.PUBLIC && <TextInput
          label={`Nombre personalizado del enlace (token) ${id ? '*' : ''}`}
          fieldName='token'
          value={token}
          required={id ? true : false}
          onChange={(data: React.ChangeEvent<HTMLInputElement>) => setToken(data.target.value)}
          onBlur={handleVerify}
        /> }
        <h5>Vista previsa enalce:</h5>
        <div style={{ display: 'flex', alignItems: 'center'}}>
          {validToken ? <svg style={{marginRight: '6px'}} version="1.1" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 512 512"><path fill="#4BCA81" d="M432 64l-240 240-112-112-80 80 192 192 320-320z"></path></svg> : <svg style={{marginRight: '6px'}}  version="1.1" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 512 512"><path fill="#FF0033" d="M507.331 411.33c-0.002-0.002-0.004-0.004-0.006-0.005l-155.322-155.325 155.322-155.325c0.002-0.002 0.004-0.003 0.006-0.005 1.672-1.673 2.881-3.627 3.656-5.708 2.123-5.688 0.912-12.341-3.662-16.915l-73.373-73.373c-4.574-4.573-11.225-5.783-16.914-3.66-2.080 0.775-4.035 1.984-5.709 3.655 0 0.002-0.002 0.003-0.004 0.005l-155.324 155.326-155.324-155.325c-0.002-0.002-0.003-0.003-0.005-0.005-1.673-1.671-3.627-2.88-5.707-3.655-5.69-2.124-12.341-0.913-16.915 3.66l-73.374 73.374c-4.574 4.574-5.784 11.226-3.661 16.914 0.776 2.080 1.985 4.036 3.656 5.708 0.002 0.001 0.003 0.003 0.005 0.005l155.325 155.324-155.325 155.326c-0.001 0.002-0.003 0.003-0.004 0.005-1.671 1.673-2.88 3.627-3.657 5.707-2.124 5.688-0.913 12.341 3.661 16.915l73.374 73.373c4.575 4.574 11.226 5.784 16.915 3.661 2.080-0.776 4.035-1.985 5.708-3.656 0.001-0.002 0.003-0.003 0.005-0.005l155.324-155.325 155.324 155.325c0.002 0.001 0.004 0.003 0.006 0.004 1.674 1.672 3.627 2.881 5.707 3.657 5.689 2.123 12.342 0.913 16.914-3.661l73.373-73.374c4.574-4.574 5.785-11.227 3.662-16.915-0.776-2.080-1.985-4.034-3.657-5.707z"></path></svg>}
          <p style={{ margin: '8px', fontSize: '14px'}}>{`${SURVEY_URL}/${token ? token : suggestedToken}`}</p>
        </div>
        {(!validToken && token) && <p style={{ margin: '8px 2px', fontSize: '13px', color:'red'}}>El token introducido ya se encuentra en uso</p>}
        {type === TypeLink.INDIVIDUAL && <p style={{ margin: '8px', fontSize: '14px', fontStyle:'italic', color:'#3b5998'}}>Los enlaces individuales solo sirven para un solo uso hasta que el usuario complete su encuesta o esta expire</p>}
        <h5>Configurar metadatos</h5>
        {fields?.map((field) => {
          if (field.catalogObj){
            return (
              <SelectInput
                key={field._id}
                label={`${field.display.es} ${field.fieldRequired ? '*' : ''}`}
                noneLabel='Selecciona una opción'
                selectedValue={linkParams[field.fieldName]}
                onChange={(data: string) => setLinkParams(Object.assign({ ...linkParams}, {[field.fieldName]: data}))}
                options={field.catalogObj.options.map((option) => { return {_id: option.distinctId, name: option.displayValue.es}}) }
                required={field.fieldRequired}
                style={{
                  marginBottom: '16px',
                }}
              />
            )
          }
          else {
            switch (field.dataType) {
              case DataType.STRING:
                return <TextInput
                  key={field._id}
                  label={`${field.display.es} ${field.fieldRequired ? '*' : ''}`}
                  fieldName={field.fieldName}
                  value={linkParams[field.fieldName]}
                  onChange={(data: React.ChangeEvent<HTMLInputElement>) => setLinkParams(Object.assign({ ...linkParams}, {[field.fieldName]: data.target.value}))}
                  required={field.fieldRequired}
                  style={{
                    marginBottom: '16px',
                  }}
                />
              case DataType.NUMBER:
                return <TextInput
                  key={field._id}
                  label={`${field.display.es} ${field.fieldRequired ? '*' : ''}`}
                  fieldName={field.fieldName}
                  value={linkParams[field.fieldName]}
                  onChange={(data: React.ChangeEvent<HTMLInputElement>) => setLinkParams(Object.assign({ ...linkParams}, {[field.fieldName]: data.target.value}))}
                  type='number'
                  required={field.fieldRequired}
                  style={{
                    marginBottom: '16px',
                  }}
                />
                case DataType.DATE:
                  return <TextInput
                    key={field._id}
                    label={`${field.display.es} ${field.fieldRequired ? '*' : ''}`}
                    type='date'
                    fieldName={field.fieldName}
                    value={linkParams[field.fieldName]}
                    onChange={(data: React.ChangeEvent<HTMLInputElement>) => setLinkParams(Object.assign({ ...linkParams}, {[field.fieldName]: data.target.value}))}
                    required={field.fieldRequired}
                    style={{
                      marginBottom: '16px',
                      width: '150px'
                    }}
                  />
              default:
                return null;
            }
          }
        })
        }
        {type === TypeLink.PUBLIC && 
          <>
          <h5>Establecer limites de respuestas opcional</h5>
          <TextInput
            label={`Numero maximo de respuestas`}
            fieldName='maxAnswers'
            value={maxAnswers}
            type='number'
            onChange={(data: React.ChangeEvent<HTMLInputElement>) => setMaxAnswers(data.target.value)}
            style={{
              marginBottom: '16px'
            }}
          />
          <SelectInput
            label='Establece si el número de respuestas será por hora / dia / semana / mes o ninguno'
            noneLabel='Ninguno'
            selectedValue={timeUnit}
            onChange={(data: string) => {setTimeUnit(data)}}
            options={[
              {_id: 'hour', name: 'Hora'},
              {_id: 'day', name: 'Día'},
              {_id: 'week', name: 'Semana'},
              {_id: 'month', name: 'Mes'},
            ]}
            style={{
              marginBottom: '16px'
            }}
          />
          </>}
      
        {(invitationRules && invitationRules?.length > 0 && type === TypeLink.INDIVIDUAL) && <h5>Usar reglas de dispersión opcional</h5>}
        {type === TypeLink.INDIVIDUAL && invitationRules?.map((rule) => {
          return (
            <TextInput
              key={rule._id}
              label={`${rule.name}`}
              fieldName={rule.name}
              id={rule._id}
              onChange={handleCheckboxChange}
              type='checkbox'
              help={`(${rule.description})`}
            />
          )
        })}
        <button style={{margin:'48px auto'}} className="page-action-button" type='submit' disabled={loading}>{id ? 'Actualizar enlace' : 'Crear enlace'}</button>
      </form>
      </div>
    </>
  )
}
export default InvitationTokenForm
export {
  InvitationTokenForm
}