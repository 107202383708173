import { useState, useCallback } from 'react';
import { useAppSelector } from '../../store/hooks';
import { selectSlice } from '../../store/slices/studies';
import { FilterDateRange, FilterOptions, selectDateRange, selectFilters } from '../../store/slices/filters';
import { AdditionalField, CommentT, GetCommentsOptions, getComments } from '../../services/comments';
import { DateRange } from '../../utils';
import { CommentExplorerProps } from '../../screens/comments/widgets/comment-explorer.w';

export interface UseCommentsConfig {
  dateRangeField: string;
  commentField: string;
  fieldSentiment?: string;
  additionalFields?: AdditionalField[];
  extraFilters?: FilterOptions[];
  limit?: number
  skip?: number
}

type UseCommentsHook = (config: CommentExplorerProps['query']) => [
  () => Promise<any>,
  boolean,
  Error | undefined,
  Array<CommentT> | undefined,
  FilterDateRange,
  FilterOptions[]
];

const useComments: UseCommentsHook = ({
  dateRangeField,
  commentField,
  fieldSentiment,
  additionalFields,
  extraFilters = [],
  baseFilters = [],
  limit,
  skip,
}) => {
  const studies = useAppSelector(selectSlice);
  const filters = useAppSelector(selectFilters);
  const [loading, setLoading] = useState(true);
  const [analyticsError, setAnalyticsError] = useState<Error | undefined>();
  const [analyticsResult, setAnalyticsResult] = useState<Array<CommentT> | undefined>(undefined);
  const dateRange = useAppSelector(selectDateRange);
  const hasBaseFilters = baseFilters.length;

  const execRequest = useCallback(
    async () => {
      try {
        setAnalyticsResult(undefined);
        setAnalyticsError(undefined);
        setLoading(true);
        if (!studies.selected) {
          return setAnalyticsError(new Error('No hay estudio seleccionado'));
        }
        const dateField = process.env.REACT_APP_COMMENT_FIELD_DATE ? process.env.REACT_APP_COMMENT_FIELD_DATE : 'answerAt';
        const query: GetCommentsOptions = {
          studyId: studies.selected._id,
          range: dateRange as DateRange,
          dateRangeField,
          commentField,
          additionalFields,
          pagination: {
            sort: { [dateField]: -1 },
            limit: limit !== undefined ? limit : 1000,
            skip: skip !== undefined ? skip : 0
          }
        }
        if (fieldSentiment) query.fieldSentiment= fieldSentiment;

        const extraFiltersNormalized = extraFilters.map(f => ({
          field: f.field,
          values: f.values.map(v => v.value),
        }));

        if (hasBaseFilters) {
          query.filters = [...baseFilters, ...extraFiltersNormalized]
        }

        if (filters.length) {
          const standarizedFilters = filters.map(f => ({
            field: f.field,
            values: f.values.map(v => v.value),
          }));
          if (hasBaseFilters) {
            const filtersToOverride = standarizedFilters.map(bf => bf.field)
            const newBaseFilters = baseFilters.filter(f => !filtersToOverride.includes(f.field))
            query.filters = [...newBaseFilters, ...standarizedFilters, ...extraFiltersNormalized]
          } else {
            query.filters = [...standarizedFilters, ...extraFiltersNormalized]
          }
        }
        let analyticsResponse = await getComments(query);
        setAnalyticsResult(analyticsResponse);
      } catch (err) {
        setAnalyticsError(err as Error);
      } finally {
        setLoading(false);
      }
    },
    [
      studies.selected,
      dateRange,
      filters,
      commentField,
      skip,
    ]
  );

  return [
    execRequest,
    loading,
    analyticsError,
    analyticsResult,
    dateRange,
    filters
  ]

}

export default useComments;