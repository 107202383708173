import { storage } from '..';
import { ServerResponse, constants } from '../../utils';
import { InvitationToken } from './invitation-token.models';

export interface getOptions {
  id: string;
}

export interface Verify {
  valid: boolean;
}

async function getInvitationTokens(studyId: string): Promise<InvitationToken[]> {
  const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    }
  };
  let URL = `${constants.API_URL}/invitation-token/description?conditions={"studyId": "${studyId}"}`;
  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: ServerResponse<InvitationToken[]> = await fetchResponse.json();
  const tokensList = response.data;
  if (!tokensList) throw new Error('No tokens found')

  return tokensList;
}

async function createInvitationToken (invitation: InvitationToken): Promise<ServerResponse<InvitationToken> > {
  const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(invitation),
  };
  let URL = `${constants.API_URL}/invitation-token`;
  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: ServerResponse<InvitationToken> = await fetchResponse.json();
  return response;
}

async function verifyToken (tokenVerify: string): Promise<ServerResponse<Verify> > {
  const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({token: tokenVerify}),
  };
  let URL = `${constants.API_URL}/invitation-token/verify-token`;
  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: ServerResponse<Verify> = await fetchResponse.json();
  return response;
}

async function getInvitationToken ({
  id
}: getOptions): Promise<InvitationToken> {
  const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    }
  };
  let URL = `${constants.API_URL}/invitation-token/${id}`;

  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: ServerResponse<InvitationToken> = await fetchResponse.json();
  const invitation = response.data;
  if (!invitation) throw new Error('No invitation found')
  return invitation;
}

async function updateInvitationToken (id:string, data: any):Promise<ServerResponse<InvitationToken> > {
  const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data)
  };

  let URL = `${constants.API_URL}/invitation-token/${id}`;

  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: ServerResponse<InvitationToken> = await fetchResponse.json();
  return response;
}

export {
  getInvitationTokens,
  createInvitationToken,
  getInvitationToken,
  updateInvitationToken,
  verifyToken
}