import { CenteredLayout } from '../../common/layouts';
import { InvitationTokenForm } from './widgets';
import { RouteComponentProps } from 'react-router-dom';

const NewInvitationScreen = (props: RouteComponentProps<{ id: string }>)=> {
  const id: string = props?.match?.params?.id;
  return (
    <CenteredLayout
      title={`${id ? 'Editar' : 'Crear'} enlace de invitación`}
      navbarProps={{ showControls: true, showMenu: true }}
    >
      <InvitationTokenForm id={id}/>
    </CenteredLayout>
  );
}
export default NewInvitationScreen;
export {
  NewInvitationScreen,
};