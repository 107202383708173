const StudiesEmpty = () => {
  return(
    <div style={{
      backgroundColor: 'white',
      margin:'1rem',
      width: "auto",
      height: "auto",
      padding: '1rem',
      }}>
        <p>No existen estudios para mostrar</p>
    </div>
  );
}

export default StudiesEmpty;