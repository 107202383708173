import { LocalizedText } from "../filters";

export enum StatusTickets {
  PENDING = 'pending',
  CLOSED = 'closed',
}

export enum StatusMessages {
  CREATED = 'created',
  SEND = 'send',
  ERROR = 'error',
  RECEIVED ='received',
}

export type Tag  = {
  _id: string;
  value: string;
  nameDisplay: LocalizedText;
  archived?: boolean;
  createdAt?: string;
}

export type Message = {
  _id: string;
  subject: string;
  email: string;
  body: string;
  status: StatusMessages;
  createdAt: Date;
}

export type Ticket = {
  _id: string;
  title: string;
  description: string;
  email: string;
  status: StatusTickets;
  tags: string[];
  tagsObj: Tag[];
  messages: string[];
  messagesObj: Message[];
  createdAt: Date;
};

export type TicketList = {
  _id: string;
  title: string;
  description: string;
  email: string;
  status: StatusTickets;
  tags: Tag[];
  messages: string[];
  createdAt: Date;
};

export type Tickets = Ticket[] | undefined;
export type TicketsList = TicketList[] | undefined;
