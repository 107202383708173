import React, { useEffect, useState } from 'react';
import moment, { MomentInput } from 'moment';
import { useAppSelector } from '../../../store/hooks';
import { selectSlice as selectStudiesSlice } from '../../../store/slices/studies';
import { imports as importService } from '../../../services';
import { Import, Imports, TypeImportFlow } from '../../../utils';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridLoadIcon,
  GridLoadingOverlay,
  GridColDef,
  GridValueFormatterParams,
  GridCellParams,
  GridSortModel,
} from '@mui/x-data-grid';
import { Tooltip } from '@material-ui/core';
import { DownloadFileButton } from '../../../common/components';
import { selectDateRange } from '../../../store/slices/filters';
import { translateError } from '../../../utils/handle-error';
import { DownloadType } from '../../../services/file.service';

const statusDisplay: any = {
  ready: 'Recibido',
  processing: 'Procesando',
  parsed: 'Verificado',
  registering: 'Creando contactos',
  sending: 'Enviando invitaciones',
  cancelled: 'Cancelado',
  complete: 'Enviado',
  subscription_limit_reached: 'Descartado límite'
};

function formatStatus (params: GridValueFormatterParams): string {
  const k: string = params.value as string;
  return statusDisplay[k] || '';
}

function applyOperation (operator: string, x: number, y: number) {
  const ops: Record<string, (x: number, y: number) => number> = {
    '-': (x, y) => x - y,
    '+': (x, y) => x + y,
    '*': (x, y) => x * y,
    '/': (x, y) => x / y,
  }

  return ops[operator](x, y);
}

type ImportNumberField = keyof Pick<Import, 'discardedRecords' | 'completeRecords'>

function makePercentageFormater (baseField: ImportNumberField, operator: string, lv1: ImportNumberField) {
  return function percentageFormatter (i: Import): string {
    const base: number = i[baseField] || 0;
    let n: number;
    n = i[lv1];
    if (operator) {
      // Se calcula el nuevo n
      n = applyOperation(operator, base, n);
      n = n < 0 ? 0 : n;
    }
    if (!n) return '-';
    const percentage: number = base === 0 ? 100 : Math.trunc(n / base * 100);
    return `${n} (${percentage}%)`;
  }
}

interface EnrichedImport extends Import {
  originalname: string;
  discardedRecordsFormatted: string;
  validRecordsFormatted: string;
  canDownloadDiscarded: boolean;
  canDownloadValid: boolean;
}

const enrichImports = (imports: Imports = []): EnrichedImport[] => {
  const formatDiscarded = makePercentageFormater('completeRecords', '', 'discardedRecords');
  const formatValid = makePercentageFormater('completeRecords', '-', 'discardedRecords');

  return imports?.map(i => ({
    ...i,
    originalname: i.fileObj ? i.fileObj.originalname : `Carga_${ moment(i.createdAt).format('DD_MM_YYYY_HH:mm')}.csv`,
    discardedRecordsFormatted:  i.status === 'cancelled' ? i.completeRecords.toString() : formatDiscarded(i),
    validRecordsFormatted: i.status === 'complete' ? formatValid(i) : '-',
    canDownloadDiscarded: (i.status !== 'subscription_limit_reached' && i.discardedRecords > 0) || i.status === 'cancelled',
    canDownloadValid: (i.completeRecords - i.discardedRecords) > 0 && i.status === 'complete',
    typeInput: i.typeInput,
    id: i._id
  }))
}

function formatDate (params: GridValueFormatterParams): string {
  const v: MomentInput = params.value as string;
  const m = moment(v);
  return m.format('DD/MM/YYYY HH:mm');
}

const cols: GridColDef[] = [
  {
    field: 'originalname',
    headerName: 'Nombre de archivo',
    flex: 1,
    disableColumnMenu: true,
    renderCell: ({ value }: GridCellParams) => (
      <Tooltip title={value || ''} >
        <span>{value}</span>
      </Tooltip>
    ),
  },
  {
    field: 'status',
    headerName: 'Estado',
    description: 'Muestra el estado en el que se encuentra el procesamiento del archivo.',
    valueFormatter: formatStatus,
    flex: 0.5,
    disableColumnMenu: true,
  },
  {
    field: 'createdAt',
    headerName: 'Fecha de carga',
    type: 'dateTime',
    valueFormatter: formatDate,
    flex: 0.5,
    disableColumnMenu: true,
  },
  {
    field: 'completeRecords',
    headerName: 'Cargados',
    flex: 0.35,
    disableColumnMenu: true,
    align: 'right',
    headerAlign: 'right',
  },
  {
    field: 'checkInId',
    headerName: ' ',
    flex: 0.25,
    disableColumnMenu: true,
    align: 'center',
    disableExport: true,
    renderCell: ({ row }: GridCellParams) => (
      <Tooltip title="Descargar archivo original" >
        <div>
          <DownloadFileButton
            downloadType={row.typeInput === 'JSON' ? DownloadType.ORIGINAL : DownloadType.IMPORT}
            downloadId={row.typeInput === 'JSON' ? row.id : row.fileId}
            downloadFileName={`${row.originalname}`}
          />
        </div>
      </Tooltip>
    ),
    sortable: false,
  },
  {
    field: 'discardedRecordsFormatted',
    headerName: 'Descartados',
    flex: 0.45,
    disableColumnMenu: true,
    align: 'right',
    headerAlign: 'right',
  },
  {
    field: '_id',
    headerName: ' ',
    flex: 0.25,
    disableColumnMenu: true,
    align: 'center',
    disableExport: true,
    renderCell: ({ row }: GridCellParams) => (
      <Tooltip title="Descargar registros descartados">
        <div>
          <DownloadFileButton
            downloadType={DownloadType.DISCARDED}
            downloadId={row?._id}
            downloadFileName={`Descartados_${row.originalname}`}
            disabled={!row?.canDownloadDiscarded}
          />
        </div>
      </Tooltip>
    ),
    sortable: false,
  },
  {
    field: 'validRecordsFormatted',
    headerName: 'Válidos',
    flex: 0.35,
    disableColumnMenu: true,
    align: 'right',
    headerAlign: 'right',
  },
  {
    field: '_id2',
    headerName: ' ',
    flex: 0.25,
    disableColumnMenu: true,
    align: 'center',
    disableExport: true,
    renderCell: ({ row }: GridCellParams) => (
      <Tooltip title="Descargar registros válidos">
        <div>
          <DownloadFileButton
            downloadType={DownloadType.VALID}
            downloadId={row?._id}
            downloadFileName={`Validos_${row.originalname}`}
            disabled={!row?.canDownloadValid}
          />
        </div>
      </Tooltip>
    ),
    sortable: false,
  },
];

const ToolbarIconStyles: React.CSSProperties = {
  padding: 0,
  lineHeight: 1,
  background: 'none',
  color: process.env.REACT_APP_PRIMARY_COLOR ? process.env.REACT_APP_PRIMARY_COLOR : 'red',
  marginRight: '1em',
};

const ImportsTableToolbar = (toolbarProps: any) => {
  return (
    <GridToolbarContainer
      style={{ justifyContent: 'space-between' }}
    >
      <b style={{ paddingLeft: '12px' }}>{toolbarProps?.tableTitle}</b>
      <div style={{display: 'flex'}}>
        <button
          onClick={toolbarProps?.onReload}
          style={ToolbarIconStyles}
        >
          <GridLoadIcon fontSize="small" />
        </button>
        <GridToolbarExport />
      </div>
    </GridToolbarContainer>
  );
}

const ImportsTable = () => {

  const [imports, setImports] = useState<Import[]>([]);
  const [loading, setLoading] = useState(true);
  const studies = useAppSelector(selectStudiesSlice);
  const dateRange = useAppSelector(selectDateRange);
  const [sortModel, setSortModel] = useState<GridSortModel>([{
    field: 'createdAt',
    sort: 'desc'
  }]);

  async function loadImports (): Promise<void> {
    setLoading(true);
    try {
      const result = await importService.getImports(studies.selected?._id, {
        createdAt: {
          $gte: dateRange.start?.startOf('day'),
          $lte: dateRange.end?.endOf('day'),
        },
        type:  {$nin: [TypeImportFlow.ONLY_INVITE, TypeImportFlow.ENRICH_AND_CREATE_CONTACT]}
      });
      if (result) {
        const importList = enrichImports(result);
        setImports(importList);
      }
    } catch (err) {
      const error = translateError(err);
      alert(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    loadImports();
  }, [studies.selected, dateRange]);

  return (
    <div style={{ flex: 1, backgroundColor: 'white', borderRadius: 14, padding: '16px 0' }}>
      <DataGrid
        density="compact"
        getRowId={r => r._id}
        columns={cols}
        rows={imports}
        disableSelectionOnClick
        disableColumnFilter
        disableDensitySelector
        disableColumnSelector
        sortModel={sortModel}
        onSortModelChange={(model: GridSortModel) => setSortModel(model)}
        loading={loading}
        localeText={{
          noRowsLabel: 'No se encontraron registros.',
          toolbarExport: '',
          toolbarExportCSV: 'Descargar CSV',
          toolbarExportLabel: 'Exportar',
          MuiTablePagination: {
            labelRowsPerPage: 'Registros por página:',
            labelDisplayedRows: ({ from, to, count }) => `${from} - ${to} de ${count}`,
          }
        }}
        components={{
          Toolbar: ImportsTableToolbar,
          LoadingOverlay: GridLoadingOverlay,
        }}
        componentsProps={{
          toolbar: {
            onReload: loadImports,
            tableTitle: 'Archivos importados'
          }
        }}
      />
    </div>
  );
}

export default ImportsTable;
export {
  ImportsTable
};