import { storage } from '.';
import { constants } from '../utils';
import { Fields, InvitationRules, ServerResponse } from '../utils/models'

export interface ResponseFields {
  fields: Fields, 
  token: string,
  invitationRules: InvitationRules
}

async function getFieldsSurvey (surveyId: string, type: string): Promise<ResponseFields> {
  const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({surveyId, type}),
  };
  let URL = `${constants.API_URL}/survey/fields`;
  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: ServerResponse<ResponseFields> = await fetchResponse.json();
  return response.data;
}


export {
  getFieldsSurvey
}