import { FC } from 'react';
import { MainLayout } from '../../common/layouts';
import { PageHeader } from '../../common/components';
import { TicketExplorer } from './widgets/ticket-explorer.w';

interface TicketsScreenProps {
  status: string
};

const TicketsScreen: FC<TicketsScreenProps> = ({
  status
}) => {

  return (
    <MainLayout>
      <PageHeader
        title="Tickets"
        showFilters
        showRangeInput
        showMainFilters
      />
      <TicketExplorer baseFilters={[{ field: "status", values: [status === "pending"  ? "pending" : "closed"]}] }/>
    </MainLayout>
  );
}

export {
  TicketsScreen,
}