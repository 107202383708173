import { MainLayout } from '../../common/layouts';
import { PageHeader, TextInput } from '../../common/components';
import { RouteComponentProps } from 'react-router-dom';
import { StatusTickets, Ticket, answerTicket, getTicket, updateTicket } from '../../services/tickets';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { MessageCard } from './widgets/message-card';
import { validate } from '../../utils';
import { Modal } from '../../common/components/modal';

const TicketDetailScreen = (props: RouteComponentProps<{ id: string }>) => {
  const id: string = props?.match?.params?.id;
  const [loading, setLoading] = useState(true);
  const [ticket, setTicket] =  useState<Ticket>()
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [errors, setErrors] = useState(null);
  const [loadingModal, setLoadingModal] = useState<boolean>(false)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [textModal, setTextModal] = useState<string>('')

  async function loadTicket (id: string) {
    setLoading(true);
    try {
      const response = await getTicket({ticketId: id})
      if (response) setTicket(response)
    } catch (error) {
      console.log('Error: ', error)
    }
    finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    loadTicket(id);
  }, [id]);

  const fn: any = {
    subject: setSubject,
    body: setBody
  };

  function onValueChange (e: any): void {
    e.preventDefault();
    const newValue: string = e.target.value;
    const fieldName: string = e.target.name;
    fn[fieldName](newValue);
  }

  function convertHtml (inputText: string) {
    const lines = inputText.split('\n');
    const tagsP = [];

    for (let i = 0; i < lines.length; i++) {
      if (lines[i].trim() !== '') {
        tagsP.push(`<p>${lines[i]}</p>`);
      } else {
        tagsP.push('<br>');
      }
    }

    return tagsP.join('');
  }

  async function handleSubmit (e: React.FormEvent): Promise<void> {
    try {
      e.preventDefault();
      setLoadingModal(true);
      validate('empty',subject);
      setErrors(null);
      setOpenModal(true);
      const response = await answerTicket(id, {
        subject,
        body: convertHtml(body)
      })
      
      if (response?._id) {
        setLoadingModal(false);
        setTextModal("El mensaje se ha creado correctamente y se ha mandado a la cola de envio")
        setBody('')
        setSubject('')
        loadTicket(id);
      }
    } catch (err:any) {
      console.log('Error: ', err?.message)
      setLoadingModal(false);
      setErrors(err?.message);
    }
    finally{
      setLoading(false);
    }
  }

  async function update(): Promise<void> {
    try {
      setLoadingModal(true);
      setOpenModal(true);
      if (ticket ) {
        const status =  ticket.status === StatusTickets.PENDING ? StatusTickets.CLOSED : StatusTickets.PENDING;
        const response = await updateTicket(id, { status })
        if (response?._id) {
          setLoadingModal(false);
          setTextModal("El ticket se ha actualizado correctamente")
          loadTicket(id);
        }
      }
    } catch (error) {
      console.log('Error: ', error)
      setLoadingModal(false);
      setTextModal("El ticket no se pudo actualizar correctamente")
    }
    finally{
      setLoading(false);
    }
  }

  if (loading || !ticket) return null;

  return (
    <MainLayout>
      <Modal
        open={openModal}
        showLoadingAnimation={loadingModal}
        text={textModal}
        onClick={ () => setOpenModal(false)}
      />
      <PageHeader
        title={"Detalle "+ ticket.title}
        showFilters={false}
        showRangeInput={false}
        showMainFilters={false}
      />
      <div style={{maxWidth: '1366px'}}>
        <div style={{display: 'flex', justifyContent:'end'}}>
          <button className="ticket-action"  onClick={() => update()} >{ticket.status === StatusTickets.PENDING ? "Cerrar ticket" : "Reabrir ticket"}</button>
        </div>
        <div className="ticket-detail-card" >
          <div className="ticket-date"><b>{ticket.status === StatusTickets.PENDING ? "Abierto" : "Cerrado" }</b> <b>{moment(ticket.createdAt).format('DD/MM/YY HH:mm')}</b></div>
          <br />
          <p className="ticket-text">{ticket.description}</p>
          <br />
          <p className="ticket-text">Email: {ticket.email}</p>
          <div className="ticket-meta">
            { ticket.tagsObj.map(tag => <div key={tag._id} className={`ticket-tag ${tag.value}`}>{tag.nameDisplay.es}</div>)}
          </div>
          <br />
          <p className="ticket-text">Mensajes:</p>
          {ticket.messagesObj?.map((message) => (
              <MessageCard key={message._id} {...message}/>
            ))
          }
          <p className="ticket-text">Responder:</p>
          <br />
          <form
            style={{ width: '1024px', position:'relative', paddingBottom: '32px' }}
            noValidate
            onSubmit={handleSubmit}
          >
            <TextInput
              label="Asunto"
              fieldName="subject"
              value={subject}
              onChange={onValueChange}
            />
            <div className="form-control">
              <label htmlFor="body" className="input-label">Mensaje</label>
              <textarea name="body" className="text-input w-input" value={body} onChange={onValueChange} style={{minHeight: '250px'}} />
            </div>
            <div style={{position: 'absolute', right: 0}}>
              <input
                disabled={loadingModal}
                type="submit"
                value={loadingModal ? 'Procesando...' : 'Enviar'}
                className="form-submit-button w-button"
                style={{width: '180px'}}
              />
            </div>
            <p style={{color: 'red'}}>
              {errors}
            </p>
          </form>
        </div>
      </div>
    </MainLayout>
  );

}

export {
  TicketDetailScreen,
}