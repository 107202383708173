import { FC } from 'react';
import moment from 'moment';
import './ticket.css';
import {  TicketList } from '../../../services/tickets/tickets.models';

export interface TicketProps extends TicketList {
  onClick: any;
}

const TicketCard: FC<TicketProps> = ({
  _id,
  email,
  description,
  title,
  tags,
  createdAt,
  onClick
}) => (
  <div key={_id} className="ticket-card" onClick={() => onClick && onClick(_id)}>
    <div className="ticket-date">{moment(createdAt).format('DD/MM/YY HH:mm')}</div>
    <p className="ticket-title">{title}</p>
    <p className="ticket-text">{description}</p>
    <div className="ticket-meta">
      { tags.map(tag => <div key={tag.value} className={`ticket-tag ${tag.value}`}>{tag.nameDisplay.es}</div>)}
    </div>
    <div className="ticket-contact">
      <span>{email}</span>
    </div>
  </div>
);

export {
  TicketCard,
}