import React, { Key, useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { storage, auth, studies } from './services';
import { constants } from './utils';
import 'react-dates/initialize';
import { useAppDispatch, useAppSelector } from './store/hooks';
import { selectAvailableViews, setAvailableViews } from './store/slices/views';
import { ViewType, listViews } from './services/views';
import StartScreen from './screens/start';
import AuthScreen from './screens/auth';
import { AnalyticsScreen } from './screens/analytics';
import { CommentsScreen } from './screens/comments';
import { ExportScreen, NewExportScreen } from './screens/exports';
import { ImportsScreen, NewImportScreen } from './screens/imports';
import { DownloadScreen } from './screens/download';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import { setAvailable, setStudy } from './store/slices/studies';
import { TicketsScreen } from './screens/tickets';
import { TicketDetailScreen } from './screens/tickets/ticket-detail.screen';
import InvitationTokenScreen from './screens/invitation-token/invitation-tokens.screen';
import NewInvitationScreen from './screens/invitation-token/new-invitation-token.screen';
import { CampaignsScreen, CampaignsSendScreen, NewUploadScreen } from './screens/campaigns';

const theme = createTheme({
  palette: {
    primary: {
      main: process.env.REACT_APP_PRIMARY_COLOR ? process.env.REACT_APP_PRIMARY_COLOR : 'red',
    },
  },
});

const ViewComponents: Record<ViewType, React.ComponentType> = {
  [ViewType.ANALYTICS]: AnalyticsScreen,
  [ViewType.COMMENT_EXPLORER]: CommentsScreen,
  [ViewType.EXPORTS]: ExportScreen,
  [ViewType.NEW_EXPORT]: NewExportScreen,
  [ViewType.IMPORTS]: ImportsScreen,
  [ViewType.NEW_IMPORT]: NewImportScreen,
  [ViewType.DOWNLOAD]: DownloadScreen,
  [ViewType.INVITATION_TOKEN]: InvitationTokenScreen,
  [ViewType.CAMPAIGNS]: CampaignsScreen,
}

function App (): JSX.Element | null {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const availableViews = useAppSelector(selectAvailableViews);

  async function tryRefresh () {
    try {
      await auth.refreshCognitoSession();
    } catch (err) {
      console.log('No se pudo actualizar sesión.');
    }
  }

  useEffect(() => {
    localStorage.setItem('lastPath', window.location.pathname);
    async function validateAuth () {
      const refreshToken = storage.getData(constants.AUTH_REFRESH_TOKEN_KEY);
      if (refreshToken) {
        try {
          await auth.refreshCognitoSession();
          const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);
          const studyList = await studies.getStudies();
          if (!studyList) return;
          const availableViews = await listViews();
          dispatch(setAvailable(studyList));
          dispatch(setAvailableViews(availableViews));

          if (token) {
            const lastPath = localStorage.getItem('lastPath');
            const studyId = localStorage.getItem('studyId');
            const username = storage.getData(constants.AUTH_USERNAME_KEY);
            console.log("Usuario: ", username.split('@')[0])
            if(lastPath && studyId) {
              dispatch(setStudy(studyId));
              history.push(lastPath === '/' ? '/start' : lastPath);
            } else {
              history.push('/start');
            }
          } else {
            history.push('/auth/login');
          }

        } catch (err) {
          history.push('/auth/login');
        }
      } else {
        history.push('/auth/login');
      }
    }

    let interval: any;
    interval = setInterval(tryRefresh, 10 * 60 * 1000); // 10 minutos
    validateAuth();
    
    return function cleanup () {
      clearInterval(interval);
    }
    
  }, [history]);

  return (
    <ThemeProvider theme={theme}>
      <Switch>
        <Route path="/auth" component={AuthScreen} />
        <Route path="/start" component={StartScreen} />
        {availableViews.map(({ viewName, viewType }) => (
          <Route key={viewName as Key} path={`/${viewName}`} component={ViewComponents[viewType]} />
        ))}
        <Route path="/pending-tickets" render={(props) => <TicketsScreen {...props} status="pending" />} />
        <Route path="/closed-tickets" render={(props) => <TicketsScreen {...props} status="closed"/>} />
        <Route path="/detail-tickets/:id" component={TicketDetailScreen} />
        <Route path="/new-link" component={NewInvitationScreen} />
        <Route path="/update-link/:id" component={NewInvitationScreen} />
        <Route path="/campaigns-send" component={CampaignsSendScreen} />
        <Route path="/campaigns-upload" component={CampaignsSendScreen} />
        <Route path="/new-upload" component={NewUploadScreen} />
      </Switch>
    </ThemeProvider>
  );
}

export default App;
