import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { Campaign } from '../../services/campaigns';

// Define a type for the slice state
interface CampaignsState {
  available: Campaign[];
  selected?: Campaign;
}

// Define the initial state using that type
const initialState: CampaignsState = {
  available: [],
};

export const campaignsSlice = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setAvailableCampaigns: (state, action: PayloadAction<Campaign[]>) => {
      state.available = action.payload;
    },
    setCampaign: (state, action: PayloadAction<string>) => {
      const nextCampaign = state.available.find(cp => cp._id === action.payload);
      if (nextCampaign) {
        state.selected = nextCampaign;
      }
    },
  },
})
export const { setAvailableCampaigns, setCampaign } = campaignsSlice.actions;
// Other code such as selectors can use the imported `RootState` type
export const selectSliceCampaigns = (state: RootState) => state.campaigns;
export const selectCampaigns = (state: RootState) => state.campaigns.available;
export default campaignsSlice.reducer;