import { useCallback, useState } from 'react';
import { InvitationTokens, getInvitationTokens } from '../../services/invitation-token';

type UseInvitationTokensHook = (config: any['query']) => [
  () => Promise<any>,
  boolean,
  Error | undefined,
  InvitationTokens
];

const useInvitationTokens: UseInvitationTokensHook = (studyId) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | undefined>();
  const [invitationTokens, setInvitationTokens] = useState<InvitationTokens>();

  const loadInvitations = useCallback(
    async () => {
      try {
        setError(undefined);
        setInvitationTokens(undefined);
        setLoading(true);
        const invitationsList = await getInvitationTokens(studyId);
        
        if (invitationsList) {
          setInvitationTokens(invitationsList);
        }
      } catch (e) {
        setError(e as Error)
      } finally {
        setLoading(false);
      }
    },
    [studyId]
  );

  return [
    loadInvitations,
    loading,
    error,
    invitationTokens,
  ]

}

export default useInvitationTokens;