import { useState, useCallback } from 'react';
import { useAppSelector } from '../../store/hooks';
import { selectSlice } from '../../store/slices/studies';
import { selectCompare, selectCompareRange, selectDateRange, selectFilters } from '../../store/slices/filters';
import { reports } from '../../services';
import { KpiSummaryReport, KpiSummaryReportQuery } from '../../services/reports';

type UseKpiSummaryHook = (config: any['query']) => [
  () => Promise<any>,
  boolean,
  Error | undefined,
  KpiSummaryReport | undefined
];

const useKpiSummary: UseKpiSummaryHook = ({
  dateRangeField,
  field,
  calc,
  baseFilters = [],
  compareWithPreviosPeriod
}) => {
  const studies = useAppSelector(selectSlice);
  const filters = useAppSelector(selectFilters);
  const [loading, setLoading] = useState(true);
  const [analyticsError, setAnalyticsError] = useState<Error | undefined>();
  const [analyticsResult, setAnalyticsResult] = useState<KpiSummaryReport | undefined>(undefined);
  const dateRange = useAppSelector(selectDateRange);
  const compareDateRange = useAppSelector(selectCompareRange);
  const compareCheck = useAppSelector(selectCompare);
  const hasBaseFilters = baseFilters.length
  let compare = compareWithPreviosPeriod;

  const execRequest = useCallback(
    async () => {
      try {
        setAnalyticsResult(undefined);
        setAnalyticsError(undefined);
        setLoading(true);
        if (!studies.selected) {
          return setAnalyticsError(new Error('No hay estudio seleccionado'));
        }
        if (!dateRange.start || !dateRange.end ) {
          return setAnalyticsError(new Error('No hay periodo de tiempo seleccionado'));
        }
        if (!compareDateRange.start || !compareDateRange.end || !compareCheck) {
          compare = false;
        }
        const query: KpiSummaryReportQuery = {
          studyId: studies.selected._id,
          dateRange: {
            field: dateRangeField,
            start: dateRange.start.startOf('day').toISOString(),
            end: dateRange.end.endOf('day').toISOString(),
          },
          field,
          calc
        }
        if(compare && compareDateRange.start && compareDateRange.end) {
          query.compareDateRange = {
            start: compareDateRange.start.startOf('day').toISOString(),
            end: compareDateRange.end.endOf('day').toISOString(),
          }
        }
        if (hasBaseFilters) {
          query.filters = baseFilters
        }
        if (filters.length) {
          const standarizedFilters = filters.map((f:any )=> ({
            field: f.field,
            values: f.values.map((v:any )=> v.value),
          }));
          if (hasBaseFilters) {
            const filtersToOverride = standarizedFilters.map(bf => bf.field)
            const newBaseFilters = baseFilters.filter((f:any) => !filtersToOverride.includes(f.field))
            query.filters = [...newBaseFilters, ...standarizedFilters]
          } else {
            query.filters = standarizedFilters
          }
        }
        let analyticsResponse = await reports.getKpiSummaryReport(query);
        setAnalyticsResult(analyticsResponse);
      } catch (err) {
        setAnalyticsError(err as Error);
      } finally {
        setLoading(false);
      }
    },
    [
      studies.selected,
      dateRange,
      filters,
    ]
  );

  return [
    execRequest,
    loading,
    analyticsError,
    analyticsResult,
  ]

}

export default useKpiSummary;