import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from '../../../store/hooks';
import { selectSlice } from '../../../store/slices/studies';
import { imports } from '../../../services'
import { TypeImportFlow } from '../../../utils';
import { selectSliceCampaigns } from '../../../store/slices/campaigns';

export interface FileUploadFormProps {
}

const FileUploadForm: FC<FileUploadFormProps> = () => {
  const history = useHistory();
  const [selectedFile, setSelectedFile] = useState <FileList>();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const studies = useAppSelector(selectSlice);
  const campaigns = useAppSelector(selectSliceCampaigns);
  const changeHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
    let files = e.target.files;
    let file = files?.[0];
    if (!files || !file) {
      setSelectedFile(undefined)
      setIsFilePicked(false)
      return
    };
    const acceptedMimeTypes = [
      'text/csv',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ]
    
    if (file.name.endsWith('.csv') || acceptedMimeTypes.includes(file.type)) {
      setSelectedFile(files)
      setIsFilePicked(true)
    } else {
      setSelectedFile(undefined)
      setIsFilePicked(false)
      e.target.value = '';
      alert(`Por favor selecciona un archivo CSV. Archivo seleccionado: ${file.name} (${file.type})`);
    }
  }
	const handleSubmission = async () => {
    if (selectedFile && studies.selected) {
      setLoading(true);
      try {
        if (campaigns.selected){
          await imports.importByFile(studies.selected._id, campaigns.selected.importDatamapId, selectedFile, TypeImportFlow.ENRICH_AND_CREATE_CONTACT, campaigns.selected._id)
          history.push('/campaigns-upload');
        }
      } catch (error) {
        console.error('Error:', error);
        alert('Ocurrió un problema al importar el archivo.');
      } finally {
        setLoading(false);
      }
    }
  };
  const message = isFilePicked ? `Se cargarán y procesarán los contactos del archivo ${selectedFile?.[0].name}` : `Por favor selecciona un archivo CSV con contactos para el estudio ${studies.selected?.name}.`;
  return (
    <div>
      <div className="file-input">
        <input type="file" name="file" onChange={changeHandler} style={{ marginBottom: 30, width: '100%' }}/>
        <div className="input-guide">
          {message}
        </div>
      </div>
      <input
        disabled={!isFilePicked || loading}
        type="submit"
        value={loading ? `Cargando archivo...` : `Cargar archivo`}
        className="upload-button"
        onClick={handleSubmission}
      />
    </div>
  );
}

export default FileUploadForm;
export {
  FileUploadForm,
}