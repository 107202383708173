import React, { FC, InputHTMLAttributes, useState } from 'react';
import { file } from '../../services';
import { GridSaveAltIcon, GridLoadIcon } from '@mui/x-data-grid';
import { DownloadType } from '../../services/file.service';

interface DownloadFileButtonProps extends InputHTMLAttributes<HTMLButtonElement> {
  downloadType: DownloadType;
  downloadId: string;
  downloadFileName?: string;
}

const DownloadFileButton: FC<DownloadFileButtonProps> = ({
  downloadType,
  downloadId,
  downloadFileName = 'Exportación.csv',
  disabled: disabledButton,
  ...buttonProps
}) => {

  const [loading, setLoading] = useState(false);

  function saveFileAs (blob: BlobPart, suggestedName: string) {
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${suggestedName}`);
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
    return;
  }
  
  async function downloadFile () {
    try {
      setLoading(true);
      let blob: BlobPart = await file.downloadCSV(downloadType, downloadId);
      saveFileAs(blob, downloadFileName);
    } catch (err) {
      console.log(`Problema al descargar ${downloadId}`, err);
    } finally {
      setLoading(false);
    }
  }

  return (
    <button
      style={{ background: 'none' }}
      onClick={downloadFile}
      disabled={disabledButton || loading}
    >
      { loading
        ? <GridLoadIcon />
        : <GridSaveAltIcon />
      }
    </button>
  );

}

export default DownloadFileButton;
export { DownloadFileButton };