import React, { useEffect, useState } from 'react';
import moment, { MomentInput } from 'moment';
import { useAppSelector } from '../../../store/hooks';
import { selectSlice as selectStudiesSlice } from '../../../store/slices/studies';
import { exports as exportService } from '../../../services';
import { Export } from '../../../utils';
import {
  DataGrid,
  GridToolbarContainer,
  GridLoadIcon,
  GridLoadingOverlay,
  GridColDef,
  GridValueGetterParams,
  GridValueFormatterParams,
  GridCellParams,
  GridSortModel,
  GridFilterModel,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { Tooltip } from '@material-ui/core';
import { DownloadFileButton } from '../../../common/components';
import { DownloadType } from '../../../services/file.service';

const statusDisplay: any = {
  ready: 'Procesando',
  exporting: 'Procesando',
  cancelled: 'Cancelado',
  complete: 'Completo',
};

const exportType: Record<string, string> = {
  json: 'Automática',
  csv: 'Manual',
};

/*function makeNestedValueGetter (lvl1: string, lvl2: string) {
  return function getNested (params: GridValueGetterParams): string {
    return `${params.row?.[lvl1]?.[lvl2]}`;
  }
}*/

function formatStatus (params: GridValueFormatterParams): string {
  const k: string = params.value as string;
  return statusDisplay[k] || '';
}

function formatType (params: GridValueFormatterParams): string {
  const k: string = params.value as string;
  return exportType[k] || '';
}

function formatDate (params: GridValueFormatterParams): string {
  const v: MomentInput = params.value as string;
  const m = moment(v);
  return m.format('DD/MM/YYYY HH:mm');
}

function getCompoundName (params: GridValueGetterParams):string {
  const resourceName = params?.row?.surveyObj?.name || 'Todos los cuestionarios';
  const range = params?.row?.dateRange;
  if (!range) return '';
  const start = moment(range?.start).format('DD/MM/YY');
  const end = moment(range?.end).format('DD/MM/YY');
  return `${resourceName} ${start} - ${end}`;
}

const cols: GridColDef[] = [
  {
    field: 'surveyId',
    headerName: 'Intervalo de exportación',
    valueGetter: getCompoundName,
    flex: 1.25,
    disableColumnMenu: true,
    renderCell: ({ value }: GridCellParams) => (
      <Tooltip title={value || ''} >
        <span>{value}</span>
      </Tooltip>
    ),
  },
  {
    field: 'format',
    type: 'string',
    headerName: 'Tipo',
    description: 'Tipo de exportación realizada',
    valueFormatter: formatType,
    flex: 0.5,
    disableColumnMenu: true,
  },
  {
    field: 'status',
    headerName: 'Estado',
    description: 'Muestra el estado en el que se encuentra el procesamiento del archivo.',
    valueFormatter: formatStatus,
    flex: 0.5,
    disableColumnMenu: true,
  },
  {
    field: 'createdAt',
    headerName: 'Fecha de creación',
    type: 'dateTime',
    valueFormatter: formatDate,
    flex: 0.75,
    disableColumnMenu: true,
  },
  {
    field: 'totalInteractions',
    headerName: 'Respuestas',
    flex: 0.75,
    disableColumnMenu: true,
    align: 'right',
    headerAlign: 'right',
  },
  {
    field: 'id',
    headerName: ' ',
    flex: 0.25,
    disableColumnMenu: true,
    renderCell: ({ row, value: id }: GridCellParams) => (
      <Tooltip title="Descargar respuestas" >
        <div>
          <DownloadFileButton
            downloadType={DownloadType.EXPORT}
            downloadId={id as string}
            downloadFileName={`Respuestas_${getCompoundName({ row } as GridValueGetterParams)}.${row?.format ?? '.csv'}`}
            disabled={!(row?.totalInteractions > 0)}
          />
        </div>
      </Tooltip>
    ),
    sortable: false,
  },
];

const ToolbarIconStyles: React.CSSProperties = {
  padding: 0,
  lineHeight: 1,
  background: 'none',
  color: process.env.REACT_APP_PRIMARY_COLOR ? process.env.REACT_APP_PRIMARY_COLOR : 'red',
  marginRight: '1em',
};

const ExportsTableToolbar = (toolbarProps: any) => {
  return (
    <GridToolbarContainer
      style={{ justifyContent: 'space-between' }}
    >
      <b style={{ paddingLeft: '12px' }}>{toolbarProps?.tableTitle}</b>
      <div style={{display: 'flex'}}>
        <GridToolbarFilterButton />
        <button
          onClick={toolbarProps?.onReload}
          style={ToolbarIconStyles}
        >
          <GridLoadIcon fontSize="small" />
        </button>
      </div>
    </GridToolbarContainer>
  );
}

const ExportsTable = () => {

  const [exports, setExports] = useState<Export[]>([]);
  const [loading, setLoading] = useState(true);
  const studies = useAppSelector(selectStudiesSlice);
  const [filterModel, setFilterModel] = React.useState<GridFilterModel | undefined>({
    items: [
      { columnField: 'format',  operatorValue: 'equals', value: 'csv' },
    ]
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([{
    field: 'createdAt',
    sort: 'desc'
  }])

  async function loadExports (): Promise<void> {
    setLoading(true);
    try {
      const exportList = await exportService.getExports(studies.selected?._id);
      if (exportList) {
        setExports(exportList);
      }
    } catch (err: unknown) {
      console.log('Ocurrió un problema al cargar los registros.');
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    loadExports();
  }, [studies.selected]);

  return (
    <div style={{ flex: 1, backgroundColor: 'white', borderRadius: 14, padding: '16px 0' }}>
      <DataGrid
        density="compact"
        getRowId={r => r._id}
        columns={cols}
        rows={exports}
        disableSelectionOnClick
        disableDensitySelector
        disableColumnSelector
        sortModel={sortModel}
        onSortModelChange={(model: GridSortModel) => setSortModel(model)}
        loading={loading}
        filterModel={filterModel}
        onFilterModelChange={(filterModel: GridFilterModel) => setFilterModel(filterModel)}
        localeText={{
          noRowsLabel: 'No se encontraron registros.',
          toolbarExport: '',
          toolbarFilters: '',
          toolbarExportCSV: 'Descargar CSV',
          toolbarExportLabel: 'Exportar',
          MuiTablePagination: {
            labelRowsPerPage: 'Registros por página:',
            labelDisplayedRows: ({ from, to, count }) => `${from} - ${to} de ${count}`,
          }
        }}
        components={{
          Toolbar: ExportsTableToolbar,
          LoadingOverlay: GridLoadingOverlay,
        }}
        componentsProps={{
          toolbar: {
            onReload: loadExports,
            tableTitle: 'Archivos generados'
          }
        }}
      />
    </div>
  );
}

export default ExportsTable;
export {
  ExportsTable
};