import { useCallback, useState } from 'react';
import { TicketsList, getTicketsList } from '../../services/tickets';
import { useAppSelector } from '../../store/hooks';
import { selectDateRange, selectFilters } from '../../store/slices/filters';
import { DateRange } from '../../utils';

type UseTicketsHook = (config: {
  baseFilters?: any;
}) => [
  () => Promise<any>,
  boolean,
  Error | undefined,
  TicketsList
];

const useTickets: UseTicketsHook = ({
  baseFilters
}) => {

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | undefined>();
  const [tickets, setTickets] = useState<TicketsList>();
  const filters = useAppSelector(selectFilters);
  const dateRange = useAppSelector(selectDateRange);

  const loadTickets = useCallback(
    async () => {
      try {
        setError(undefined);
        setTickets(undefined);
        setLoading(true);

        const query:any = {
          range: dateRange as DateRange,
          filters,
        }
        const hasBaseFilters = baseFilters.length

        if (hasBaseFilters) {
          query.filters = baseFilters
        }

        if (filters.length > 0) {
          const standarizedFilters = filters.map(f => ({
            field: f.field,
            values: f.values.map(v => v.value),
          }));
          if (hasBaseFilters) {
            const filtersToOverride = standarizedFilters.map(bf => bf.field)
            const newBaseFilters = baseFilters.filter((f:any) => !filtersToOverride.includes(f.field))
            query.filters = [...newBaseFilters, ...standarizedFilters]
          } else {
            query.filters = standarizedFilters
          }
        }
        const ticketsList = await getTicketsList(query);
        
        if (ticketsList) {
          setTickets(ticketsList);
        }
      } catch (e) {
        setError(e as Error)
      } finally {
        setLoading(false);
      }
    },
    [ 
      dateRange,
      filters,
      baseFilters
    ]
  );

  return [
    loadTickets,
    loading,
    error,
    tickets,
  ]

}

export default useTickets;