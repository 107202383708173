import React from "react";
import { LocalizedText, SupportedLanguages } from "../../services/filters";

interface CardMessageProps {
  message?: LocalizedText;
  lang?: SupportedLanguages;
  title?: string;
  className?: string;
}

const CardMessage = ({
  lang = SupportedLanguages.es,
  message = { es: "Cargando...", default: "Loading..." },
  title,
  className
}: CardMessageProps) => (
  <div className={`chart-widget-card-responsive ${className}`}>
    {title && (
      <div className="chart-widget-card-header">
        <div>
          <b>{title}</b>
        </div>
      </div>
    )}
    <div>{message[lang]}</div>
  </div>
);

export { CardMessage };
