const LOCAL_STORAGE_PREFIX = 'cxn_';
const AUTH_USERNAME_KEY = `${LOCAL_STORAGE_PREFIX}auth_username`;
const AUTH_USER_TOKEN_KEY = `${LOCAL_STORAGE_PREFIX}auth_user_token`;
const AUTH_REFRESH_TOKEN_KEY = `${LOCAL_STORAGE_PREFIX}auth_refresh_token`;
const API_ROOT_DOMAIN = 'cx-mate.com'
const DEFAULT_ENVIRONMENT = 'next';
const ENVIRONMENT =
  !window.location.host.includes('localhost') && window.location.host.endsWith(API_ROOT_DOMAIN) ?
  window.location.host.split('.')[1] :
  DEFAULT_ENVIRONMENT;
const API_HOST = `api.${ENVIRONMENT}.${API_ROOT_DOMAIN}`;
//const API_URL = `https://${API_HOST}`;
const API_URL = `https://api.viva.cx-mate.com`;
//const API_URL = `http://localhost:3000`;
const SURVEY_URL = 'https://survey.viva.cx-mate.com'

export {
  API_HOST,
  API_URL,
  AUTH_USERNAME_KEY,
  AUTH_USER_TOKEN_KEY,
  AUTH_REFRESH_TOKEN_KEY,
  SURVEY_URL
}
