import { FC, useState } from 'react';
import {  Campaign, StatusCampaign, updateCampaign } from '../../../services/campaigns';
import './campaign.css';
import moment from 'moment';
const parser = require('cron-parser');

export interface CampaignProps extends Campaign {
  onRedirect: any
}

const CampaignCard: FC<CampaignProps> = ({
  _id,
  name,
  description,
  timeExpression,
  surveyObj,
  status,
  onRedirect
}) => {
  const interval = parser.parseExpression(timeExpression);
  const [loadUpdate, setLoading] = useState(true);
  async function onUpdate(): Promise<void> {
    try {
      setLoading(true);
      if (status ) {
        const newStatus =  status === StatusCampaign.ACTIVE ? StatusCampaign.DISABLED : StatusCampaign.ACTIVE;
        const response = await updateCampaign(_id, { status: newStatus })
        if (response) {
          window.location.reload();
        }
      }
    } catch (error) {
      console.log('Error: ', error)
      alert("La campaña no se pudo actualizar correctamente")
    }
    finally{
      setLoading(false);
    }
  }
  
  return (
  <div className="campaign-card">
    <p className="campaign-title">{name}</p>
    <p className="campaign-text">{description}</p>
    <p className="campaign-text">Encuesta: {surveyObj?.name}</p>
    <br />
    {status === 'active' 
    ? <>
        <p className="campaign-text">Proximas ejecuciones:</p>
        <p className="campaign-text">{moment(interval.next().toString()).format('DD/MM/YY HH:mm a')}</p>
        <p className="campaign-text">{moment(interval.next().toString()).format('DD/MM/YY HH:mm a')}</p>
        <p className="campaign-text">{moment(interval.next().toString()).format('DD/MM/YY HH:mm a')}</p>
      </>
    :  <p className="campaign-text">No hay ejecuciones programadas</p>}
    <div className="campaign-status">{status === 'active' ? "Activa" : "Desactivada"}</div>
    <div className='campaign-actions-container'>
      <button className="campaign-button" disabled={!loadUpdate ? true : false} onClick={onUpdate}>{status === 'active' ? "Desactivar campaña" : "Activar campaña"}</button>
      <button className="campaign-button" disabled={!loadUpdate ? true : false} onClick={() => onRedirect('/campaigns-upload', _id)}>Cargar contactos</button>
      <button className="campaign-button" disabled={!loadUpdate ? true : false} onClick={() => onRedirect('/campaigns-send', _id)}>Listar envios</button>
    </div>
  </div>
)};

export {
  CampaignCard,
}