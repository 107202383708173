import React, { FC, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { clearFilters, hideFilterBar, selectFiltersSlice, setFilters, setFiltersActive } from '../../../store/slices/filters';
import { MultiSelect, Option } from 'react-multi-select-component';
import { SelectedFilter } from './selected-filter';
import { CloseIcon, TrashIcon } from '../../icons';
import './filter-bar.css';

const FilterBar: FC = () => {

  const filters = useAppSelector(selectFiltersSlice);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [visibleFilterName, setVisibleFilter] = useState<string>();
  const selectedFilterNames = filters.selected.map(sf => sf.field);
  const availableFilters = filters.available.filter(af => !selectedFilterNames.includes(af.field));
  const selectionMode = !loading && !!visibleFilterName;
  const visibleFilter = filters.available.find(af => af.field === visibleFilterName);
  const visibleFilterSelected = filters.selected.find(sf => sf.field === visibleFilterName)?.values;

  function onFilterChange (fieldDisplay: string, fieldName: string, values: any[]) {
    dispatch(setFilters({
      display: fieldDisplay,
      field: fieldName,
      values,
    }));
  }

  function resetModeAndClose () {
    setVisibleFilter(undefined);
    dispatch(hideFilterBar());
  }

  function clearAndClose () {
    dispatch(hideFilterBar());
    if (filters.active.length > 0) {
      dispatch(clearFilters());
    }
  }

  return (
    <div className={`filter-bar ${filters.showBar ? 'open' : ''}`}>
      <div className="filter-bar-header">
        <h3>Filtros</h3>
        <div className="filter-bar-close-button" onClick={resetModeAndClose}>
          <CloseIcon />
        </div>
      </div>
      <div className="filter-bar-body">
        {loading && (
          <p>Cargando filtros...</p>
        )}
        {!selectionMode && (
          filters.selected.map(sf => <SelectedFilter key={sf.field} onClick={() => setVisibleFilter(sf.field)} {...sf} />)
        )}
        {(!selectionMode && availableFilters.length > 0) && (
          <div className="available-filters">
            <div className="available-filters-title">Agregar filtro:</div>
            {availableFilters.map(availableFilter => (
              <button
                className="available-filters-pill"
                key={availableFilter.field}
                onClick={() => setVisibleFilter(availableFilter.field)}
                >
                {availableFilter.display}
              </button>
            ))}
          </div>
        )}
        {(selectionMode && visibleFilter) && (
          <MultiSelect
            key={visibleFilter.field}
            options={visibleFilter.values || []}
            value={visibleFilterSelected || []}
            onChange={(values: Option[]) => onFilterChange(visibleFilter.display, visibleFilter.field, values)}
            labelledBy="Filtro"
            isOpen
            valueRenderer={(selected, allOptions) => `${visibleFilter.display}: ${selected.length} seleccionados`}
            disableSearch={visibleFilter.values.length < 6}
            overrideStrings={{
              allItemsAreSelected: 'Todos seleccionados',
              clearSearch: 'Limpiar',
              noOptions: 'No hay opciones',
              search: 'Buscar',
              selectAll: 'Seleccionar todo',
              selectSomeItems: `Seleccionar ${visibleFilter.display}`,
            }}
          />
        )}
      </div>
      <div className="filter-bar-footer">
      {!selectionMode
        ? (
          <>
            <button
              className="filter-bar-primary-button"
              onClick={() => dispatch(setFiltersActive())}
              >
              Aplicar filtros
            </button>
            <button
              onClick={clearAndClose}
              className="filter-bar-icon-button"
            >
              <TrashIcon />
            </button>
          </>
        ) : (
          <button
            className="filter-bar-primary-button"
            onClick={() => setVisibleFilter(undefined)}
          >
            {visibleFilterSelected && visibleFilterSelected.length > 0 ? 'Agregar filtro' : 'Volver'}
          </button>
        )}
      </div>
    </div>
  );

}

export {
  FilterBar
}