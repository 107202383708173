import { Study, Survey } from "../../utils/models";

export enum StatusCampaign {
  ACTIVE = 'active',
  DISABLED = 'disabled',
}

export type Campaign = {
  _id: string;
  name: string;
  timeExpression: string;
  filter: string;
  defaultResult: boolean;
  description: string;
  studyId: string;
  studyObj?: Study;
  surveyId: string;
  surveyObj?: Survey
  importDatamapId: string;
  status?: StatusCampaign;
  archived?: boolean;
  createdAt?: string;
}

export type Campaigns = Campaign[] | undefined;