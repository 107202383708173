import { Filter } from "../analytics/analytics.models";
import { RemoteFilter, LocalizedText } from "../filters";

export enum ViewType {
  ANALYTICS = 'analytics',
  COMMENT_EXPLORER = 'comments',
  EXPORTS = 'exports',
  NEW_EXPORT = 'new-export',
  IMPORTS = 'imports',
  NEW_IMPORT = 'new-import',
  DOWNLOAD = 'download',
  INVITATION_TOKEN ='invitation-token',
  CAMPAIGNS = 'campaigns',
}

export enum ReportTypes {
  KPI_SUMMARY = 'KPI_SUMMARY',
  KPI_TREND = 'KPI_TREND',
  MULTIVAR_BREAKDOWN = 'MULTIVAR_BREAKDOWN',
  MULTIMETRIC_TREND = 'MULTIMETRIC_TREND',
  NPS_BREAKDOWN = 'NPS_BREAKDOWN',
  PERFORMANCE = 'PERFORMANCE',
  COMMENTS = 'COMMENTS',
  DOWNLOAD_REPORT = 'DOWNLOAD_REPORT',
  KPI_COUNT = "KPI_COUNT",
  KPI_COUNT_TABLE = "KPI_COUNT_TABLE",
  CSAT = 'CSAT',
  QUESTION_BREAKDOWN = 'QUESTION_BREAKDOWN',
  DATA_LIST = 'DATA_LIST',
  SUMMARY_IMPORTED_RECORDS = 'SUMMARY_IMPORTED_RECORDS'
}

export interface ReportConfig<Q = any, O = any> {
  type: ReportTypes;
  title?: LocalizedText;
  query: Q & { baseFilters?: Filter[] };
  options: O;
}

export type BaseViewOptions = {
  pageTitle: string,
  showOnNavbar?: boolean,
  showMainFilters?: boolean,
  showCompareRangeInput?: boolean,
}

export type BannerButtonConfig = {
  label: LocalizedText;
  link: string;
}

export type InfoMessageConfig = {
  title: LocalizedText;
  description: LocalizedText;
  buttons?: BannerButtonConfig[];
}

export type ViewConfig<O = any> = {
  id?: string;
  reports: ReportConfig[];
  studyId?: string;
  viewType: ViewType
  viewName: string;
  viewOptions: O;
  filtersId: string[];
  filtersObj: RemoteFilter[];
  mainFiltersId: string[];
  mainFiltersObj: RemoteFilter[];
  infoMessage?: InfoMessageConfig;
};

export type SimpleViewConfig<O = BaseViewOptions> =
  Pick<ViewConfig<O>, 'id' | 'reports' | 'studyId' | 'viewType' | 'viewName' | 'viewOptions'>
