import { Moment } from 'moment';
export interface MomentRange {
  start?: Moment;
  end?: Moment;
}

export interface Study {
  _id: string;
  name: string;
  importDatamapId: string;
  surveys: string[]
}

export interface Import {
  _id: string;
  id: string;
  createdAt: string;
  completeRecords: number;
  datamapId: string;
  discardedRecords: number;
  fileId: string;
  fileObj: {
    _id: string;
    originalname: string;
  }
  status: string;
  studyId: string;
  typeInput: string;
  type?:  TypeImportFlow;
  updatedAt: string;
}

export interface Export {
  _id: string;
  createdAt: string;
  totalInteractions: number; // Total respuestas procesados
  discardInteractions: number; // Total respuestas descartados
  fileId: string;
  status: string;
  surveyId: string;
}

export interface Survey {
  _id: string;
  name: string;
}

export enum AgentPhase {
  INICIO = 'Inicio',
  DURANTE = 'Durante',
  ABANDONADOR = 'Abandonador',
  POST = 'Post',
}

export enum CompanyPhase {
  ACCESS = 'Access',
  EXCHANGE = 'Information Exchange',
  MOMENTUM = 'Momentum',
  LOST = 'Lost',
  KAM = 'Kam',
}

export type ServerResponse<T> = {
  message: any;
  data: T
}

export interface Languages {
  es: string;
  default: string;
}

export enum DataType {
  STRING = 'string',
  NUMBER = 'number',
  BOOLEAN = 'boolean',
  FUNCTION = 'function',
  DATE = 'date',
}

export enum FieldType {
  METRIC = 'metric',
  DIMENSION = 'dimension',
  INFO = 'info',
}

export interface Field {
  _id: string;
  fieldName: string;
  sourceName: string;
  display: Languages;
  fieldOwner?: string;
  dataType?: DataType;
  fieldRequired?: boolean;
  defaultValue?: string | number | boolean;
  fieldType?: FieldType;
  version: string;
  transformation?: string;
  datamapId: string;
  generateSets?: boolean;
  subSetsFieldId?: string;
  catalogId?: string;
  catalogObj?: Catalog;
  requiredFindInCatalog?: boolean;
  createdAt?: string;
  validations?: Array<string>;
  relevance?: number;
}

export interface InvitationRule {
  _id: string;
  name: string;
  description: string;
}

export interface Options {
  inputValue: Array<string | number | Array<string | number>>;
  displayValue: Languages;
  distinctId: string;
}

export interface Catalog {
  _id: string;
  name: string;
  studyId?: string;
  studyObj?: Study;
  options: Options[];
  createdAt?: string;
}

export enum TypeImportFlow {
  ENRICH_AND_CREATE_CONTACT = 'ENRICH_AND_CREATE_CONTACT', //Importar nuevos contactos
  ONLY_INVITE = 'ONLY_INVITE', //Campañas
  ENRICH_AND_INVITE = 'ENRICH_AND_INVITE', //Default
  ENRICH_INVITE_AND_CREATE_CONTACT = 'ENRICH_INVITE_AND_CREATE_CONTACT', //Default y crea contactos
}

export type Phase = AgentPhase | CompanyPhase;

export type DateRange = MomentRange | undefined;

export type Studies = Study[] | undefined;

export type Surveys = Survey[] | undefined;

export type Imports = Import[] | undefined;

export type Exports = Export[] | undefined;

export type Fields = Field[] | undefined;

export type InvitationRules = InvitationRule[] | undefined;