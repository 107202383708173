import { storage } from '..';
import { ServerResponse, constants } from '../../utils';
import { Campaign, Campaigns } from './campaigns.models';

async function listCampaigns(studyId: string): Promise<Campaigns> {
  const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    }
  };
  let URL = `${constants.API_URL}/campaign/description?conditions={"studyId": "${studyId}"}`;
  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: ServerResponse<Campaigns> = await fetchResponse.json();
  const campaignList = response.data;
  if (!campaignList) throw new Error('No campaigns found')
  return campaignList;
}

async function updateCampaign (id:string, data: any): Promise<boolean> {
  const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data)
  };
  let URL = `${constants.API_URL}/campaign/${id}`;
  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: ServerResponse<Campaign> = await fetchResponse.json();
  const message = response.data;
  if (!message) throw new Error('No campaign found')
  return true;
}

export {
  listCampaigns,
  updateCampaign
}