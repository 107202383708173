import { FC } from "react";

interface AppsCardProps {
  name: string;
  path: string;
}

const AppsCard: FC<AppsCardProps> = ({
  name
}) => {
  return (
    <div className="apps-card">
      <div>{name}</div>
      <ul className="study-card-menu">
        <a href='/pending-tickets'>
          <li
            key='pending'
            className="study-card-link"
          >
            Pendientes
          </li>
        </a>
        <a href='/closed-tickets'>
          <li
            key='closed'
            className="study-card-link"
          >
            Cerrados
          </li>
        </a>
      </ul>
    </div>
  )
}

export default AppsCard;