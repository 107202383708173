import { storage } from '..';
import { constants } from '../../utils';
import { GetCommentsOptions, GetCommentsRequest, CommentsServerResponse, CommentT, PaginationOptions } from './comments.models';

async function getComments (options: GetCommentsOptions): Promise<CommentT[]> {

  const {
    studyId,
    range,
    dateRangeField = 'createdAt',
    commentField,
    fieldSentiment,
    additionalFields,
    filters,
    pagination
  } = options;

  const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);
  if (!range?.start || !range?.end) throw new Error ('Falta rango de fecha.');
  let body: GetCommentsRequest = {
    studyId,
    dateRange: {
      field: dateRangeField,
      start: range.start.startOf('day').toISOString(),
      end: range.end.endOf('day').toISOString(),
    },
    commentField,
  };
  if(fieldSentiment) body.fieldSentiment = fieldSentiment;
  if (filters && filters.length > 0) {
    body.filters = filters
  }
  if (additionalFields) {
    body.additionalFields = additionalFields;
  }
  const fetchOpts: RequestInit = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body),
  };
  let URL = `${constants.API_URL}/analytics/text-analysis/comments`;
  if (pagination) {
    URL += Object.keys(pagination).reduce((urlParams, paramName, i) => {
      const separator = i === 0 ? '?' : '&'
      const paramValue = JSON.stringify(pagination[paramName as keyof PaginationOptions])

      return urlParams += `${separator}${paramName}=${paramValue}`
    }, '')
  }
  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: CommentsServerResponse = await fetchResponse.json();
  return response.comments;
}

async function updateTags (id: string, answerId: string, tag: string): Promise<boolean> {
  const motives = tag.split(':');
  const fieldMotiveTag = process.env.REACT_APP_MOTIVETAG_FIELD ? process.env.REACT_APP_MOTIVETAG_FIELD : 'P2';
  let body = {
    data: {
      [`${fieldMotiveTag}_motive`]: motives[0],
      [`${fieldMotiveTag}_submotive`]: motives[1],
      [`${fieldMotiveTag}_submotive_tag`]: tag,
      manualVerification: 'true',
      requiresVerification: 'false',
    },
    field: 'data',
    resultId: id
  };
  const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body),
  };
  let URL = `${constants.API_URL}/answer/updateObj/${answerId}`;
  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: any = await fetchResponse.json();
  if (response.data) return true
  else return false
}

export {
  getComments,
  updateTags
}