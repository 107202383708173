import { storage } from '..';
import { ServerResponse, constants } from '../../utils';
import { PaginationOptions } from '../comments';
import {
  CSATAttributesReport,
  CSATAttributesReportQuery,
  GetDataListOptions,
  GetDataListRequest,
  KpiSummaryReport,
  KpiSummaryReportQuery,
  KpiTrendReport,
  KpiTrendReportQuery,
  MultimetricTrendReport,
  MultimetricTrendReportQuery,
  MultivarTrendReport,
  MultivarTrendReportQuery, NpsBreakdownReport, NpsBreakdownReportQuery,
  ReportResponse,
  SegmentedReport,
  SegmentedReportQuery,
  SegmentedReportResponse,
  SummaryImportedRecords
} from './reports.models';


async function getSegmentedReport (query: SegmentedReportQuery): Promise<SegmentedReport> {

  const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(query)
  };

  let URL = `${constants.API_URL}/analytics/segmented-report`;

  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: SegmentedReportResponse = await fetchResponse.json();
  const viewConfig = response.report;
  if (!viewConfig) throw new Error('No view config found')

  return viewConfig;
}

async function getKpiSummaryReport (query: KpiSummaryReportQuery): Promise<KpiSummaryReport> {

  const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(query)
  };

  let URL = `${constants.API_URL}/analytics/general-report`;

  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: ReportResponse<KpiSummaryReport> = await fetchResponse.json();
  const report = response.report;
  if (!report) throw new Error('No report found')

  return report;
}

async function getKpiTrendReport (query: KpiTrendReportQuery): Promise<KpiTrendReport> {

  const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(query)
  };

  let URL = `${constants.API_URL}/analytics/trend-report`;

  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: ReportResponse<KpiTrendReport> = await fetchResponse.json();
  const report = response.report;
  if (!report) throw new Error('No report found')

  return report;
}

async function getMultimetricTrendReport (query: MultimetricTrendReportQuery): Promise<MultimetricTrendReport> {

  const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(query)
  };

  let URL = `${constants.API_URL}/analytics/multimetric-trend-report`;

  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: ReportResponse<MultimetricTrendReport> = await fetchResponse.json();
  const report = response.report;
  if (!report) throw new Error('No report found')

  return report;
}

async function getDownloadReport (query: MultimetricTrendReportQuery): Promise<any> {

  const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(query)
  };

  let URL = `${constants.API_URL}/analytics/report-csv`;

  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: any = await fetchResponse;
  const report = response.ok ? response.blob(): response.json();
  if (!report) throw new Error('No report found')

  return report;
}

async function getMultivarTrendReport (query: MultivarTrendReportQuery): Promise<MultivarTrendReport> {

  const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(query)
  };

  let URL = `${constants.API_URL}/analytics/multimetric-trend-report`;

  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: ReportResponse<MultivarTrendReport> = await fetchResponse.json();
  const report = response.report;
  if (!report) throw new Error('No report found')

  return report;
}

async function getNpsBreakdownReport (query: NpsBreakdownReportQuery): Promise<NpsBreakdownReport> {

  const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(query)
  };

  let URL = `${constants.API_URL}/analytics/nps-breakdown`;

  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: ReportResponse<NpsBreakdownReport> = await fetchResponse.json();
  const report = response.report;
  if (!report) throw new Error('No report found')

  return report;
}

async function getCSATAttributesReport (query: CSATAttributesReportQuery): Promise<CSATAttributesReport> {
  const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(query)
  };
  let URL = `${constants.API_URL}/analytics/csat-attributes`;
  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: ReportResponse<CSATAttributesReport> = await fetchResponse.json();
  const report = response.report;
  if (!report) throw new Error('No report found')
  return report;
}

async function getDataListReport (options: GetDataListOptions): Promise<any[]> {

  const {
    studyId,
    range,
    dateRangeField = 'createdAt',
    mainField,
    valueMainField,
    additionalFields,
    filters,
    display,
    pagination
  } = options;

  const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);
  if (!range?.start || !range?.end) throw new Error ('Falta rango de fecha.');
  let body: GetDataListRequest = {
    studyId,
    dateRange: {
      field: dateRangeField,
      start: range.start.startOf('day').toISOString(),
      end: range.end.endOf('day').toISOString(),
    },
    mainField,
    valueMainField,
  };
  if (filters && filters.length > 0) {
    body.filters = filters
  }
  if (additionalFields) {
    body.additionalFields = additionalFields;
  }
  if (display) {
    body.display = display;
  }
  let URL = `${constants.API_URL}/analytics/data-list`;
  if (pagination) {
    URL += Object.keys(pagination).reduce((urlParams, paramName, i) => {
      const separator = i === 0 ? '?' : '&'
      const paramValue = JSON.stringify(pagination[paramName as keyof PaginationOptions])

      return urlParams += `${separator}${paramName}=${paramValue}`
    }, '')
  }
  const fetchOpts: RequestInit = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body),
  };
  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: any = await fetchResponse.json();
  return response.data;
}

async function getSummaryImportedRecords (studyId: string, conditions?: any): Promise<SummaryImportedRecords> {
  const token = storage.getData(constants.AUTH_USER_TOKEN_KEY);
  const fetchOpts: RequestInit = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({collection: "summaryimportedrecords"}),
  };
  let URL = `${constants.API_URL}/analytics/generic-report`;
  let fullConditions = {};
  if (conditions) {
    fullConditions = conditions;
  }
  if (studyId) {
    fullConditions = { ...fullConditions, studyId }
  }
  if (conditions || studyId) {
    URL += `?conditions=${JSON.stringify(fullConditions)}&sort={"importedDate":1}`
  }
  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: ServerResponse<SummaryImportedRecords> = await fetchResponse.json();
  return response.data;
}

export {
  getSegmentedReport,
  getKpiSummaryReport,
  getKpiTrendReport,
  getMultimetricTrendReport,
  getMultivarTrendReport,
  getNpsBreakdownReport,
  getDownloadReport,
  getCSATAttributesReport,
  getDataListReport,
  getSummaryImportedRecords
}
