import { FC, useEffect } from 'react';
import useCampaigns from '../../../common/hooks/use-campaigns';
import { useHistory } from 'react-router-dom';
import { CampaignCard } from './campaign-card';
import './campaign.css';
import { setAvailableCampaigns, setCampaign } from '../../../store/slices/campaigns';
import { useAppDispatch } from '../../../store/hooks';

export interface CampaignExplorerProps  {
}

const CampaignExplorer: FC<CampaignExplorerProps>  = () => {
  const [loadCampaigns, loading, error, campaigns] = useCampaigns();
  const history = useHistory();
  const dispatch = useAppDispatch();
  function onRedirect (path: string, campaignId: string ) {
    dispatch(setCampaign(campaignId));
    history.push(path);
  }
  useEffect(() => {
    loadCampaigns();
    if(campaigns) dispatch(setAvailableCampaigns(campaigns));
  }, [loadCampaigns]);

  useEffect(() => {
    if(!loading && !error && campaigns) dispatch(setAvailableCampaigns(campaigns));
  }, [loading, error, campaigns]);
  
  return (
    <div className="campaign-explorer">
      <div className="campaign-list">
        {campaigns?.map((campaign) => (
            <CampaignCard key={campaign._id} {...campaign} onRedirect={onRedirect}/>
          ))
        }
        {loading && <p>Cargando datos</p>}
        {campaigns && campaigns?.length < 1 && <p style={{fontSize: '18px'}}>No existen campañas creadas</p>} 
        </div>
    </div>
  );
}

export { CampaignExplorer }