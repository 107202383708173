import { useEffect, useState } from 'react'
import BaseTable from '../../../common/widgets/table.w'
import { GridColDef, GridValueFormatterParams } from '@mui/x-data-grid'
import { ReportConfig } from '../../../services/views'
import { SupportedLanguages } from '../../../services/filters'
import  { UseDataListConfig } from '../../../common/hooks/use-data-list'
import useSummaryImportedRecords from '../../../common/hooks/use-summary-imported-records'
import { MomentInput } from 'moment'
import moment from 'moment'

function formatDate (params: GridValueFormatterParams): string {
  const v: MomentInput = params.value as string;
  const m = moment(v).utc();
  return m.format('DD/MM/YYYY');
}

export interface ScaleFormatDef {
  max: number,
  className: string,
}

export type DataListProps = ReportConfig<
  UseDataListConfig,
  {
    limit?: number;
    hidePagination: boolean;
  }
>

const baseColProps: Partial<GridColDef> = {
  headerAlign: 'left',
  align: 'left',
  type: 'string',
  sortable: true,
}

const SummaryReportTable = ({
  title = { default: '', es: '' },
  query,
  options: {
    limit,
    hidePagination
  },
}: DataListProps) => {
  const [loadReport, loading, error, reportDataList] = useSummaryImportedRecords(query);
  const [tableCols, setTableCols] = useState<GridColDef[]>([]);
  const [data, setData] = useState<Array<any>>([]);
  const preferredLang = SupportedLanguages.es;
  const hide = hidePagination ? hidePagination : false;
  const cols: GridColDef[] = [
    {
      ...baseColProps,
      field: 'departureStation',
      headerName: 'Estación Origen',
      flex: 0.5
    },
    {
      ...baseColProps,
      field: 'arrivalStation',
      headerName: 'Estación Destino',
      flex: 0.5
    },
    {
      ...baseColProps,
      field: 'count',
      headerName: 'Conteo',
      flex: 0.5
    },
    {
      ...baseColProps,
      field: 'importedDate',
      headerName: 'Fecha importación',
      valueFormatter: formatDate,
      type: 'dateTime',
      flex: 0.5
    },
  ];
  useEffect(() => {
    loadReport();
  }, [loadReport]);
  useEffect(() => {
    if (!loading && !error && reportDataList) {
      setTableCols(cols);
      setData(reportDataList)
    }
  }, [loading, error, reportDataList]);
  
  return (
    <div className="span-two-cols">
      <BaseTable
        rowIdKey="_id"
        rows={data}
        columns={tableCols}
        loading={loading}
        onReload={loadReport}
        rowHeight={32}
        autoHeight
        tableTitle={title[preferredLang]}
        downloadFileName={title[preferredLang]}
        pageSize={limit}
        hideFooter={hide}
        localeText={{
          toolbarFilters: '',
          toolbarExport: '',
          toolbarColumns: 'Columnas',
          noRowsLabel: 'No se encontraron registros.',
          toolbarExportCSV: 'Descargar CSV',
          toolbarExportLabel: 'Exportar',
          toolbarFiltersTooltipShow: 'Mostrar filtros',
          toolbarFiltersTooltipHide: 'Ocultar filtros',
          MuiTablePagination: {
            labelRowsPerPage: 'Registros por página:',
            labelDisplayedRows: ({ from, to, count }) => `${from} - ${to} de ${count}`,
          }
        }}
      />
    </div>
  )
}

export {
  SummaryReportTable
}