import { useCallback, useState } from 'react';
import { listCampaigns, Campaigns } from '../../services/campaigns';
import { useAppSelector } from '../../store/hooks';
import { selectSlice } from '../../store/slices/studies';

type UseCampaignsHook = () => [
  () => Promise<any>,
  boolean,
  Error | undefined,
  Campaigns
];

const useCampaigns: UseCampaignsHook = () => {
  const studies = useAppSelector(selectSlice);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | undefined>();
  const [campaigns, setCampaigns] = useState<Campaigns>();
  const loadCampaigns = useCallback(
    async () => {
      try {
        setError(undefined);
        setCampaigns(undefined);
        setLoading(true);
        if (!studies.selected) {
          return setError(new Error('No hay estudio seleccionado'));
        }
        const campaignsList = await listCampaigns(studies.selected._id);
        if (campaignsList) {
          setCampaigns(campaignsList);
        }
      } catch (e) {
        setError(e as Error)
      } finally {
        setLoading(false);
      }
    },
    [studies.selected]
  );
  return [
    loadCampaigns,
    loading,
    error,
    campaigns,
  ]
}

export default useCampaigns;