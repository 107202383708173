import { useHistory } from 'react-router-dom';
import { MainLayout } from '../../common/layouts';
import { PageHeader } from '../../common/components'
import { CampaignsTable } from './widgets';

const CampaignsSendScreen = () => {
  const history = useHistory();
  const send =  window.location.pathname === '/campaigns-send'
  function goToNewImport () {
    return history.push(`/new-upload/`)
  }
  return (
    <MainLayout>
      <PageHeader
        title={send ? 'Campaña envios' :  'Campaña cargas'}
        actionLabel= {send ? undefined : "Nueva carga"}
        action={send ? undefined : goToNewImport}
        showFilters={false}
        showRangeInput
        showMainFilters={false}
      />
      <CampaignsTable/>
    </MainLayout>
  );
}

export default CampaignsSendScreen;
export {
  CampaignsSendScreen,
};